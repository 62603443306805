import { Card, Form, Button } from 'react-bootstrap'
import { useState, useContext } from 'react'

import GenericModalNote from '../Modal/GenericModalNote'
import AppContext from '../../store/AppContext'
import CollectiveService from '../../services/collective.service'

function CollectiveAdd(props) {
    const appContext = useContext(AppContext)
    const [familyId, setfamilyId] = useState()

    const onChange = (e) => {
        setfamilyId(e.target.value)
    }
    const addMember = async ({ familyId, consumerId, note }) => {
        try {
            await CollectiveService.addMember(familyId, consumerId, note)
            props.notify('tr', 'success', `Хэрэглэгчийг коллектив групп руу амжилттай нэмлээ.`)
            appContext.refreshConsumer()
        } catch (err) {
            props.notify('tr', 'danger', err.message)
        }
    }
    return (
        <Card>
            <Card.Header>
                <Card.Title as="h4">Collective мэдээлэл</Card.Title>
            </Card.Header>
            <Card.Body>
                <h5>Коллективд бүртгэлгүй байна</h5>
                <Form>
                    <Form.Group>
                        <label>Collective Group ID</label>
                        <Form.Control
                            value={familyId || ''}
                            placeholder="Collective ID"
                            type="text"
                            onChange={onChange}
                        ></Form.Control>
                    </Form.Group>
                    <GenericModalNote
                        onClick={addMember}
                        params={{ consumerId: appContext.consumer._id, familyId: familyId }}
                        action={'Группт нэмэх'}
                        title={'Групп-т нэмэх'}
                        body={`Та ${appContext.consumer.mobile} дугаартай гишүүнийг ${familyId} групп руу нэмэхдээ итгэлтэй байна уу?`}
                    />
                </Form>
            </Card.Body>
        </Card>
    )
}
export default CollectiveAdd
