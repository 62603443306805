import { Form, Button, Col, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import AppContext from "store/AppContext";
import { useContext } from "react";
import cardService from "services/card.service";
export default function DdishCard(props) {
  const { mobile, card } = props;
  const appContext = useContext(AppContext);
  const removeDdishCard = async () => {
    try {
      const result = await cardService.removeCard(
        card?.partner?.name,
        card?.number,
        mobile,
      );
      appContext.notify("tr", "success", result.message);
      appContext.refreshConsumer();
    } catch (err) {
      appContext.notify("tr", "daner", err.message);
    }
  };
  return (
    <>
      <Row>
        <Col sm="12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              removeDdishCard();
            }}
          >
            <Row>
              <Col sm="3">
                <Form.Label>Утасны дугаар</Form.Label>
                <Form.Control
                  className="form-control mr-sm-2"
                  type="text"
                  value={mobile}
                  readOnly
                  ref={appContext.inputRef}
                />
              </Col>
              <Col sm="3">
                <Form.Label>Одоогийн картын дугаар</Form.Label>
                <Form.Control
                  className="form-control mr-sm-2"
                  type="text"
                  value={card.number}
                  readOnly
                  ref={appContext.inputRef}
                />
              </Col>
              <Col>
                <Button className="btn btn=primary" type="submit">
                  Салгах
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}
