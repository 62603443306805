import { useState, useEffect } from "react";
import {
  Card,
  Form,
  Col,
  Table,
  Row,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import MerchantServices from "../services/merchant.service";
import Xyp from "../views/xyr";

function CheckTransaction() {
  const [merchant, setMerchant] = useState([]);
  const [allMerchants, setAllMerchants] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState([]);
  const [withoutTransactions, setWithoutTransaction] = useState([]);
  const styleGreen = { color: "green" };
  const styleRed = { color: "red" };

  useEffect(async () => {
    let AllMerchants = await MerchantServices.GetRequest("/merchant");
    let withoutTransaction = await MerchantServices.withoutTransaction(
      "/check/get_merchants"
    );
    withoutTransaction.sort((m1, m2) => {
      if (m1.name > m2.name) return 1;
      if (m1.name < m2.name) return -1;
      else return 0;
    });
    setWithoutTransaction(withoutTransaction);
    setAllMerchants(AllMerchants);
    setSelectedMerchant(AllMerchants[0]._id);
  }, []);

  const fetchData = async () => {
    let merchants = await MerchantServices.getTransactions(
      "/check/transaction"
    );
    merchants.sort((m1, m2) => {
      if (m1.name > m2.name) return 1;
      if (m1.name < m2.name) return -1;
      else return 0;
    });
    setWithoutTransaction(merchants);
  };

  const fetchMertchant = async () => {
    let merchant = await MerchantServices.checkTransaction(
      `/check/transaction/${selectedMerchant}`
    );
    setMerchant(merchant);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <i class="fas fa-exclamation-circle"></i>
      <div style={{ marginTop: 10 }}>
        Шүүх дарах болгонд бодит гүйлгээ хийгдэж байгааг анхаарна уу!
      </div>
    </Tooltip>
  );
  return (
    <>
      <Row>
        <Col md={6}>
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <Button
              onClick={() => {
                fetchData();
              }}
              style={{ width: "8rem", height: "3rem" }}
            >
              Шүүх
            </Button>
          </OverlayTrigger>
          <Col lg="9" style={{ paddingLeft: 0 }}>
            <Table
              className="table-hover table-striped"
              style={{
                fontSize: "12px",
              }}
            >
              <thead>
                <tr>
                  <th className="border-0">Салбар</th>
                  <th className="border-0">Success</th>
                  <th className="border-0">message</th>
                </tr>
              </thead>
              <tbody>
                {withoutTransactions.map((merchant, idx) => (
                  <tr key={idx}>
                    <td>{merchant.name}</td>
                    <td>
                      {merchant.success === undefined ? (
                        ""
                      ) : merchant.success === true ? (
                        <div style={{ color: "green" }}> Амжилттай </div>
                      ) : (
                        <div style={{ color: "red" }}> Амжилтгүй </div>
                      )}
                    </td>
                    <td> {merchant.message} </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Col>
        <Col md={6}>
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Form>
              <Form.Group>
                <Form.Label>Байгууллага сонгох</Form.Label>
                <Form.Control
                  as="select"
                  type="text"
                  value={selectedMerchant}
                  onChange={(e) => setSelectedMerchant(e.target.value)}
                >
                  {allMerchants.map((merchant, idx) => (
                    <option key={idx} value={merchant._id}>
                      {merchant.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
            <Button
              onClick={() => {
                fetchMertchant();
              }}
              style={{ width: "8rem", height: "3rem", marginLeft: "10px" }}
            >
              Шүүх
            </Button>
          </Row>
          <Row>
            {merchant.length !== 0 ? (
              <Table
                className="table-hover table-striped"
                style={{
                  fontSize: "12px",
                  padding: 0,
                }}
              >
                <thead>
                  <tr>
                    <th className="border-0">Салбар</th>
                    <th className="border-0">Message</th>
                    <th className="border-0">Success</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{merchant.name}</td>
                    <td> {merchant.message} </td>
                    {merchant && (
                      <td style={merchant.success ? styleGreen : styleRed}>
                        {merchant.success ? "Идэвхтэй" : "Идэвхгүй"}
                      </td>
                    )}
                  </tr>
                </tbody>
              </Table>
            ) : (
              <Card
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "10rem",
                  padding: "1rem",
                }}
              >
                <i class="fas fa-exclamation-circle"></i>
                <div style={{ marginLeft: 10 }}>
                  Шүүх дарах болгонд бодит гүйлгээ хийгдэж байгааг анхаарна уу!
                </div>
              </Card>
            )}
          </Row>
          <Xyp />
        </Col>
      </Row>
    </>
  );
}
export default CheckTransaction;
