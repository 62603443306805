import request from "./request.service";

export default {
  GetRequest: async function (url) {
    try {
      let response = await request(url, "GET", {});
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  CreateBranch: async function (merchantId, body) {
    try {
      const response = await request(`/merchant/${merchantId}`, "POST", body);
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },

  CreateEmployee: async function (merchantId, branchName, body) {
    try {
      const response = await request(
        `/merchant/${merchantId}/branch/${branchName}`,
        "POST",
        body
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  UpdateEmployee: async function (employeeId, body) {
    try {
      const response = await request(
        `/merchant/employee/${employeeId}`,
        "PATCH",
        body
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  GetEmployee: async function (url) {
    try {
      const response = await request(url, "GET");
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },

  UpdateBranch: async function (merchantId, branchName, body) {
    try {
      body = {
        name: body,
      };
      const response = await request(
        `/merchant/${merchantId}/${branchName}`,
        "PATCH",
        body
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getExcel: async function (merchantId) {
    try {
      const response = await request(`/merchant/excel/${merchantId}`, "GET");
      return response.data.data;
    } catch (err) {
      throw err;
    }
  },
  getFile: async function (fileName) {
    try {
      const response = await request(`/merchant/file/${fileName}`, "GET");
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  getTransactions: async function (url) {
    try {
      let response = await request(url, "GET", {});
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  checkTransaction: async function (merchantID, url) {
    try {
      let response = await request(merchantID, url, "GET", {});
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  withoutTransaction: async function (url) {
    try {
      let response = await request(url, "GET", {});
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  xyp: async function (url) {
    try {
      let response = await request(url, "GET", {});
      if (response.data.code === 0) {
        return response.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  tanCode: async function (phoneNumber, url) {
    try {
      let response = await request(phoneNumber, url, "GET", {});
      if (response.data.code === 0) {
        return response.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  partner: async function (url) {
    try {
      let response = await request(url, "GET", {});
      if (response.data.code === 0) {
        return response.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};
