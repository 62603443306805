import request from "./request.service";
import { getStartOfDate, getEndOfDate } from "../helpers/dateHelper";
export default {
    getTerminateNumber: async function (mobile) {
        try {
            console.log(mobile);
            const response = await request(
                "/log/get_terminate_number",
                "POST",
                {
                    mobile: mobile,
                }
            );
            if (response.data.code === 0) {
                return response.data.data;
            } else {
                throw Error(response.data.message);
            }
        } catch (err) {
            throw err;
        }
    },
};
