import { Button, Modal, Form } from "react-bootstrap";
import { useState } from "react";

function GenericModal({ onClick, params, action, title, body }) {
  const [show, setShow] = useState(false);

  const [note, setNote] = useState("");
  const onChange = (e) => {
    setNote(e.target.value);
  };

  const handleClose = () => {
    setShow(false);
    setNote("");
  };
  const handleConfirm = (e) => {
    e.preventDefault();
    params.note = note;
    onClick(params);
    setNote("");
    setShow(false);
  };

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  return (
    <>
      <a href="" onClick={handleShow}>
        {action}
      </a>

      <Modal
        style={{ fontSize: "13px" }}
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Form onSubmit={handleConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {body}
            <Form.Group>
              <label>Тайлбар</label>
              <Form.Control
                value={note}
                placeholder="Тайлбар"
                type="text"
                onChange={onChange}
                as="textarea"
                rows={3}
                required
              ></Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={handleClose}>
              Хаах
            </Button>
            <Button size="sm" type="submit">
              {action}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
export default GenericModal;
