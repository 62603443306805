import { Form, Col, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import LotteryService from "../../services/lottery.service";
import GenericModal from "../Modal/GenericModal";
function IssueTickets({ consumer, notify }) {
    const initialState = {
        chanceCount: 0,
        note: "",
    };

    const [promotions, setPromotions] = useState([]);
    const [state, setState] = useState(initialState);
    const [chosenPromotion, setChosenPromotion] = useState();

    const onChange = (evt) => {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value,
        });
    };

    const issueTickets = async () => {
        console.log(chosenPromotion);
        try {
            await LotteryService.issueTickets(
                consumer._id,
                chosenPromotion,
                state.chanceCount,
                state.note
            );
            notify(
                "tr",
                "success",
                `${consumer.mobile} дугаартай хэрэглэгчид ${state.chanceCount} хүрд эргүүлэх эрх олголоо.`
            );
            setState(initialState);
        } catch (err) {
            notify("tr", "danger", err.message);
        }
    };

    useEffect(async () => {
        try {
            let promos = await LotteryService.getPromos("LOTTERY");
            setChosenPromotion(promos[0]._id);
            setPromotions(promos);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        setState(initialState);
        console.log(state);
    }, [consumer]);

    return (
        <>
            <Form>
                <Row>
                    <Col sm="3">
                        <Form.Group controlId="formGridPassword">
                            <Form.Label>Урамшуулал</Form.Label>
                            <Form.Control
                                as="select"
                                value={chosenPromotion}
                                name={"promotion"}
                                className="form-control"
                                onChange={(e) => {
                                    setChosenPromotion(e.target.value);
                                }}
                            >
                                {promotions.map((promotion) => (
                                    <option
                                        key={promotion._id}
                                        value={promotion._id}
                                    >
                                        {promotion.name_mn}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Тайлбар</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Тайлбар"
                                value={state.note}
                                name="note"
                                onChange={onChange}
                                as="textarea"
                                rows={3}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm="2">
                        <Form.Group controlId="formGridEmail">
                            <Form.Label>Нөхөн олгох эрхийн тоо</Form.Label>
                            <Form.Control
                                type="number"
                                name={"chanceCount"}
                                value={state.chanceCount}
                                onChange={onChange}
                            />
                        </Form.Group>
                        <GenericModal
                            onClick={issueTickets}
                            params={{}}
                            action={"Эрх олгох"}
                            title={`Та эрх олгохдоо итгэлтэй байна уу.`}
                            body={`Та ${consumer.mobile} дугаартай хэрэглэгчид эрх олгохдоо итгэлтэй байна уу?`}
                            type={"button"}
                        />
                    </Col>
                    <Col sm="1"></Col>
                </Row>
            </Form>
        </>
    );
}

export default IssueTickets;
