import { useState, useEffect } from "react";
import { Card, Table, Row, Col, Button, Form } from "react-bootstrap";
import RestService from "../../services/consumer_log.service";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import {
    formatBeauty,
    getCurrentDate,
    getLastWeekDate,
} from "../../helpers/dateHelper";

function ActionLog(props) {
    const [startDate, setStartDate] = useState(getLastWeekDate());
    const [endDate, setEndDate] = useState(getCurrentDate());
    const [actions, setActions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState();
    const styleGreen = { color: "green" };
    const styleRed = { color: "red" };

    useEffect(() => {
        setActions([]);
        setStartDate(getLastWeekDate());
        setEndDate(getCurrentDate());
        setLoading(false);
    }, [props.consumer]);

    const handleSearch = async () => {
        try {
            await searchLog();
        } catch (err) {}
    };

    const searchLog = async (offset, limit) => {
        try {
            setLoading(true);
            setActions([]);
            setPagination(null);
            let data = await RestService.getConsumerLog(
                props.consumer._id,
                startDate,
                endDate,
                offset,
                limit
            );
            setActions(data.logs);
            setPagination(data.pagination);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Col md="12">
                <Card className="striped-tabled-with-hover">
                    <Card.Header>
                        <Card.Title as="h4">
                            Хэрэглэгч дээр хийгдсэн үйлдлүүд
                        </Card.Title>
                        <Form>
                            <Form.Row className="align-items-center">
                                <Form.Group
                                    as={Col}
                                    sm="2"
                                    controlId="formGridEmail"
                                >
                                    <Form.Label>Эхлэх огноо</Form.Label>
                                    <Form.Control
                                        type="date"
                                        format="yyyy-mm-dd"
                                        value={startDate}
                                        onChange={(e) => {
                                            setStartDate(e.target.value);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    sm="2"
                                    controlId="formGridPassword"
                                >
                                    <Form.Label>Дуусах огноо</Form.Label>
                                    <Form.Control
                                        type="date"
                                        format="yyyy-mm-dd"
                                        placeholder={endDate || "дуусах огноо"}
                                        value={endDate}
                                        onChange={(e) => {
                                            setEndDate(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    sm="3"
                                    controlId="formGridPassword"
                                >
                                    <Button
                                        style={{ marginTop: "20px" }}
                                        variant="primary"
                                        className="btn btn-sm"
                                        size="md"
                                        onClick={handleSearch}
                                    >
                                        Хайх
                                    </Button>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Card.Header>
                    <Card.Body className="table-full-width table-responsive px-0">
                        <Table
                            className="table-hover"
                            style={{
                                fontSize: "12px",
                            }}
                        >
                            <thead>
                                <tr>
                                    <th className="border-0">Огноо</th>
                                    <th className="border-0">Ажилтан</th>
                                    <th className="border-0">Хийсэн үйлдэл</th>
                                    <th className="border-0">IP хаяг</th>
                                    <th className="border-0">Хариу</th>
                                    <th className="border-0">Тайлбар</th>
                                    <th className="border-0">Амжилттай эсэх</th>
                                </tr>
                            </thead>
                            <tbody style={{ wordBreak: "break-all" }}>
                                {actions.map((action, key) => (
                                    <tr key={key}>
                                        <td>
                                            {formatBeauty(action.created_at)}
                                        </td>
                                        <td>{action.employee}</td>
                                        <td>{action.action}</td>
                                        <td>{action.source_address}</td>
                                        <td>{action.message || ""}</td>
                                        <td>{action.note || ""}</td>
                                        <td
                                            style={
                                                action.success
                                                    ? styleGreen
                                                    : styleRed
                                            }
                                        >
                                            {action.success
                                                ? "амжилттай"
                                                : "амжилтгүй"}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Loading loading={loading} />
                        <Pagination
                            pagination={pagination}
                            onChange={searchLog}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
}
export default ActionLog;
