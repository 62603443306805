import request from './request.service'

export default {
    getBlockedList: async function (consumerId) {
        try {
            const response = await request('/block/list', 'POST', { consumer_id: consumerId })
            if (response.data.code === 0) {
                return response.data.data
            } else {
                throw Error(response.data.message)
            }
        } catch (err) {
            throw err
        }
    },

    unblock: async function (blockId, consumerId, description) {
        try {
            const response = await request('/block/unblock', 'POST', {
                consumer_id: consumerId.toString(),
                block_id: blockId,
                description: description,
            })
            if (response.data.code === 0) {
                return response.data.data
            } else {
                throw Error(response.data.message)
            }
        } catch (err) {
            throw err
        }
    },

    getSealedList: async function (consumerId) {
        try {
            const response = await request('/seal/list', 'POST', { consumer_id: consumerId })
            if (response.data.code === 0) {
                return response.data.data
            } else {
                throw Error(response.data.message)
            }
        } catch (err) {
            throw err
        }
    },

    unseal: async function (receiptId, description) {
        try {
            const response = await request('/seal/unseal', 'POST', {
                receipt_id: receiptId,
                description: description,
            })
            if (response.data.code === 0) {
                return response.data.data
            } else {
                throw Error(response.data.message)
            }
        } catch (err) {
            throw err
        }
    },
}
