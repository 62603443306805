import { useEffect, useState } from "react";
import { Table, Row, Col, Form, Card, Button } from "react-bootstrap";
import MerchantServices from "../services/merchant.service";
import CreateBranchModal from "../components/Modal/CreateBranchModal";
import UpdateEmployee from "../components/Modal/UpdateEmployeeModal";
import UpdateBranchModal from "components/Modal/UpdateBranchModal";
import { formatPretty } from "helpers/dateHelper";
import FileDownload from "js-file-download";

function Merchant(props) {
  const UnitelID = "5bcd43443fe112b9d6160d3d";
  const styleGreen = { color: "green" };
  const styleRed = { color: "red" };

  const [ID, setID] = useState("");
  const [merchants, setMerchants] = useState([]);
  const [branches, setBranches] = useState([]);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [SelectedMerchant, setSelectedMerchant] = useState("");
  const [SelectedBranch, setSelectedBranch] = useState("");

  useEffect(async () => {
    let merchants = await MerchantServices.GetRequest("/merchant");
    let branches = await MerchantServices.GetRequest(
      `/merchant/${merchants[0]._id}`
    );
    let employees = await MerchantServices.GetRequest(
      `/merchant/${merchants[0]._id}/${branches[0].name}`
    );
    setMerchants(merchants);
    setBranches(branches);
    setEmployeeList(employees);
    setSelectedMerchant(merchants[0]._id);
    setSelectedBranch(branches[0].name);
  }, []);

  // const AllWorker = async () => {
  //   let employees = await MerchantServices.GetRequest("/employees");
  //   setEmployeeList(employees);
  // };

  const onChangeMerchant = async (val) => {
    let branches = await MerchantServices.GetRequest(`/merchant/${val}`);
    if (branches.length > 0 && branches[0].name) {
      let employees = await MerchantServices.GetRequest(
        `/merchant/${val}/${branches[0].name}`
      );
      setSelectedBranch(branches[0].name);
      setBranches(branches);
      setEmployeeList(employees);
    } else {
      setSelectedBranch([]);
      setEmployeeList([]);
      setBranches([]);
    }
  };

  const onChangeBranch = async (val) => {
    let response = await MerchantServices.GetRequest(
      `/merchant/${SelectedMerchant}/${val}`
    );
    if (response.length > 0) {
      setEmployeeList(response);
    } else {
      setEmployeeList([]);
    }
  };

  // CreateEmployee
  const initialStateEmployee = {
    position: "",
    name: "",
    dealer_number: "",
    role: 0,
    username: "",
    password: "",
  };

  const [stateEmp, setStateEmp] = useState(initialStateEmployee);

  const onChangeEmployee = (evt) => {
    setStateEmp({
      ...stateEmp,
      [evt.target.name]: evt.target.value,
    });
  };

  const onSubmitEmp = async (evt) => {
    try {
      evt.preventDefault();

      await MerchantServices.CreateEmployee(
        SelectedMerchant,
        SelectedBranch,
        stateEmp
      );
      let response = await MerchantServices.GetRequest(
        `/merchant/${SelectedMerchant}/${SelectedBranch}`
      );
      setEmployeeList(response);
      setStateEmp(initialStateEmployee);
      props.notify(
        "tr",
        "success",
        `${stateEmp.name} ажилтныг амжилттай үүсгэлээ!`
      );
    } catch (err) {
      props.notify("tr", "error", err.message);
    }
  };

  const DownloadExcel = async () => {
    try {
      let fileName = (await MerchantServices.getExcel(SelectedMerchant))
        .fileName;
      let excel = await MerchantServices.getFile(fileName);
      FileDownload(excel, fileName);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Col>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 0 1rem 0px",
          padding: "0",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <CreateBranchModal
            action={"Салбар нэмэх"}
            title={`Салбар нэмэх`}
            icon={<i class="fas fa-plus-circle"></i>}
            props={props}
          />

          <UpdateBranchModal
            action={"Салбар засах"}
            title={`Салбар засах`}
            icon={<i class="far fa-edit"></i>}
            props={props}
          />
        </div>
        <Button
          onClick={() => {
            DownloadExcel();
          }}
        >
          Тайлан татах
        </Button>
      </Row>
      <Row>
        <Col lg="4" style={{ padding: 0 }}>
          <div style={{ display: "flex" }}>
            <Col lg="6" style={{ padding: 0 }}>
              <Form.Group style={{ marginRight: "12px" }}>
                <Form.Label>Байгууллага сонгох</Form.Label>
                <Form.Control
                  as="select"
                  value={SelectedMerchant.value}
                  name="merchant"
                  className="form-control"
                  onChange={async (e) => {
                    await setSelectedMerchant(e.target.value);
                    await onChangeMerchant(e.target.value);
                  }}
                >
                  {merchants.map((merchant, i) => (
                    <option key={i} value={merchant._id}>
                      {merchant.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col lg="6" style={{ padding: 0 }}>
              <Form.Group>
                <Form.Label>Салбар сонгох</Form.Label>
                <Form.Control
                  as="select"
                  value={SelectedBranch.value}
                  name="branch"
                  onChange={async (e) => {
                    await setSelectedBranch(e.target.value);
                    await onChangeBranch(e.target.value);
                  }}
                >
                  {branches.map((branches, i) => (
                    <option key={i} value={branches.name}>
                      {branches.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </div>

          {/* createEmployee */}

          <Form onSubmit={onSubmitEmp}>
            <h4
              style={{
                color: "#3472f7",
              }}
            >
              Ажилтан нэмэх
            </h4>
            <Form.Group style={{ marginRight: "12px" }}>
              <Form.Label>Ажлын байр</Form.Label>
              <Form.Control
                value={stateEmp.position}
                name={"position"}
                type="text"
                required
                onChange={onChangeEmployee}
              ></Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Нэр</Form.Label>
              <Form.Control
                value={stateEmp.name}
                name={"name"}
                type="text"
                required
                onChange={onChangeEmployee}
              ></Form.Control>
            </Form.Group>

            {SelectedMerchant === UnitelID ? (
              <Form.Group style={{ marginRight: "12px" }}>
                <Form.Label>Dealer Number</Form.Label>
                <Form.Control
                  value={stateEmp.dealer_number}
                  name="dealer_number"
                  type="text"
                  required
                  onChange={onChangeEmployee}
                ></Form.Control>
              </Form.Group>
            ) : (
              ""
            )}
            <Form.Group style={{ marginRight: "12px" }}>
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                value={stateEmp.role}
                name="role"
                type="number"
                required
                onChange={onChangeEmployee}
              >
                <optgroup>
                  <option value={0}>{"0 идэвхгүй"}</option>
                  <option value={1}>{"1 идэвхтэй"}</option>
                </optgroup>
              </Form.Control>
            </Form.Group>
            <Form.Group style={{ marginRight: "12px" }}>
              <Form.Label>Username</Form.Label>
              <Form.Control
                value={stateEmp.username}
                name="username"
                type="text"
                required
                onChange={onChangeEmployee}
              ></Form.Control>
            </Form.Group>
            <Form.Group style={{ marginRight: "12px" }}>
              <Form.Label>Password</Form.Label>
              <Form.Control
                value={stateEmp.password}
                name={"password"}
                type="password"
                required
                onChange={onChangeEmployee}
              ></Form.Control>
            </Form.Group>
            <Button style={{ width: "10rem" }} type="submit">
              <i class="fa fa-plus-circle" style={{ marginRight: 6 }}></i>
              Үүсгэх
            </Button>
          </Form>
        </Col>

        {EmployeeList.length > 0 ? (
          <Col lg="8">
            <Table
              className="table-hover table-striped"
              style={{
                fontSize: "12px",
                width: "100%",
              }}
            >
              <thead>
                <tr>
                  <th className="border-0">Салбар</th>
                  <th className="border-0">Ажилтны нэр</th>
                  <th className="border-0">Нэвтрэх нэр</th>
                  <th className="border-0">Албан тушаал</th>
                  <th className="border-0">Идэвхтэй эсэх</th>
                  <th className="border-0">Password</th>
                  <th className="border-0">Role</th>
                  {SelectedMerchant === UnitelID ? (
                    <th className="border-0">Dealer_Number</th>
                  ) : (
                    ""
                  )}
                  <th className="border-0">Үүссэн огноо</th>
                  <th className="border-0">Action</th>
                </tr>
              </thead>
              <tbody>
                {EmployeeList.map((employee, idx) => (
                  <tr key={idx}>
                    <td>{SelectedBranch}</td>
                    <td>{employee.name}</td>
                    <td>{employee.username}</td>
                    <td> {employee.position} </td>
                    <td style={employee.is_active ? styleGreen : styleRed}>
                      {employee.is_active ? "Идэвхитэй" : "Идэвхигүй"}
                    </td>
                    <td> {employee.password} </td>
                    <td> {employee.role} </td>
                    {SelectedMerchant === UnitelID ? (
                      <td>{employee.dealer_number}</td>
                    ) : (
                      ""
                    )}
                    <td>{formatPretty(employee.created_at)}</td>
                    <td
                      onClick={() => {
                        setID(employee._id);
                      }}
                    >
                      <UpdateEmployee
                        action={"засах"}
                        title={` Ажилтны мэдээлэл засах`}
                        icon={<i class="far fa-edit"></i>}
                        employeeID={ID}
                        selectedMerchant={SelectedMerchant}
                        props={props}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        ) : (
          <Col lg="8">
            <Card
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                height: "10rem",
              }}
            >
              <i class="far fa-frown-open"></i>
              <div style={{ marginLeft: 10 }}>Хэрэглэгч олдсонгүй!</div>
            </Card>
          </Col>
        )}
      </Row>
    </Col>
  );
}

export default Merchant;
