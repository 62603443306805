import { formatPretty } from "helpers/dateHelper";
import { Card, Table, Button } from "react-bootstrap";
import GenericModalNote from "../Modal/GenericModalNote";
import CollectiveService from "../../services/collective.service";
function CollectiveMembers(props) {
  const removeMember = async ({ groupId, consumerId, note }) => {
    try {
      let result = await CollectiveService.removeMember(
        groupId,
        consumerId,
        note
      );
      props.notify(
        "tr",
        "success",
        `${consumerId} id тай хэрэгэглэгчийг амжилттай групп ээс хаслаа`
      );
      await props.refreshCollective();
    } catch (err) {
      console.log(err);
      props.notify("tr", "danger", err.message);
    }
  };
  const removeMemberWithfee = async ({ groupId, consumerId, note }) => {
    console.log("groupid:", groupId);
    try {
      const result = await CollectiveService.removeMemberFees(
        groupId,
        consumerId,
        note
      );
      props.notify("tr", "success", `Хэрэглэгчийг төлбөртэй-р групп-с хаслаа`);
      await props.refreshCollective();
    } catch (err) {
      console.log(err);
      props.notify("tr", "danger", err.message);
    }
  };
  const makeAdmin = async ({ groupId, consumerId, note }) => {
    try {
      let result = await CollectiveService.makeAdmin(groupId, consumerId, note);
      props.notify(
        "tr",
        "success",
        `${consumerId} id тай хэрэгэглэгчийг админ болголоо.`
      );
      await props.refreshCollective();
    } catch (err) {
      props.notify("tr", "danger", err.message);
    }
  };

  if (
    !props.collective ||
    !props.collective.members ||
    props.collective.members.length === 0
  ) {
    return (
      <Card>
        <Card.Header>
          <Card.Title as="h4">Collective Гишүүд</Card.Title>
        </Card.Header>
        <Card.Body>Одоогоор гишүүн байхгүй байна</Card.Body>
      </Card>
    );
  }
  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center mb-3">
        <Card.Title as="h4" className="mb-0">
          Collective Гишүүд
        </Card.Title>
        {/* <Button size="sm" type="button">
                    <i className="fa fa-plus"></i>
                    Гишүүн нэмэх
                </Button> */}
      </Card.Header>
      <Card.Body>
        <Table
          className="table-hover table-striped"
          style={{
            fontSize: "12px",
          }}
        >
          <thead>
            <tr>
              <th className="border-0">UA ID</th>
              <th className="border-0">Утасны дугаар</th>
              <th className="border-0">Овог</th>
              <th className="border-0">Нэр</th>
              <th className="border-0">Регистрийн дугаар</th>
              <th className="border-0">Элссэн огноо</th>
              <th className="border-0">Бүртгүүлсэн огноо</th>
              <th className="border-0">Role</th>
              <th className="border-0">Remove</th>
              <th className="border-0">Remove with fee</th>
              <th className="border-0">Admin</th>
            </tr>
          </thead>
          <tbody>
            {props.collective.members.map((member) => (
              <tr key={member._id}>
                <td>{member._id}</td>
                <td>{member.mobile}</td>
                <td>{member.profile.last_name || ""}</td>
                <td>{member.profile.first_name || ""}</td>
                <td>{member.profile.registration_number || ""}</td>
                <td>{formatPretty(member.join_at)}</td>
                <td>{formatPretty(member.created_at)}</td>
                <td>{member.is_admin === 1 ? "админ" : "гишүүн"}</td>
                <td>
                  <GenericModalNote
                    onClick={removeMember}
                    params={{
                      consumerId: member._id,
                      groupId: props.collective._id,
                    }}
                    action={"Хасах"}
                    title={"Групп ээс гишүүн хасах"}
                    body={`Та ${member.mobile} дугаартай гишүүнийг группээс хасахдаа итгэлтэй байна уу?`}
                  />
                </td>
                <td>
                  <GenericModalNote
                    onClick={removeMemberWithfee}
                    params={{
                      consumerId: member._id,
                      groupId: props.collective._id,
                    }}
                    action={"Төлбөртэй хасах"}
                    title={"Групп ээс гишүүн төлбөртэйгөөр хасах"}
                    body={`Та ${member.mobile} дугаартай гишүүнийг ТӨЛБӨР тэйгөөр групп ээс хасахдаа итгэлтэй байна уу?`}
                  />
                </td>
                <td>
                  {member.is_admin === 1 ? null : (
                    <GenericModalNote
                      onClick={makeAdmin}
                      params={{
                        consumerId: member._id,
                        groupId: props.collective._id,
                      }}
                      action={"Админ болгох"}
                      title={"Админ солих"}
                      body={`Та ${member.mobile} дугаартай гишүүнийг админ болгохдоо итгэлтэй байна уу?`}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
export default CollectiveMembers;
