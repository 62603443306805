import request from "./request.service";

export default {
    GetEmployee: async function (ConsumerID, url) {
        try {
            let response = await request(ConsumerID, url, "GET", {});
            if (response.data.code === 0) {
                return response.data.data;
            } else {
                throw Error(response.data.message);
            }
        } catch (err) {
            throw err;
        }
    },
    searchUser: async function (searchCriteria, searchValue) {
        try {
            const response = await request("/loyalty/consumer/search", "POST", {
                searchCriteria: searchCriteria,
                searchValue: searchValue,
            });
            if (response.data.code === 0) {
                return response.data.data;
            } else {
                throw Error(response.data.message);
            }
        } catch (err) {
            throw err;
        }
    },
    // GetEmployeeRegistration: async function (ConsumerID, url) {
    //   try {
    //     let response = await request(ConsumerID, url, "GET", {});
    //     if (response.data.code === 0) {
    //       return response.data.data;
    //     } else {
    //       throw Error(response.data.message);
    //     }
    //   } catch (err) {
    //     console.log(err);
    //     throw err;
    //   }
    // },
    // GetEmployeeReward: async function (ConsumerID, url) {
    //   try {
    //     let response = await request(ConsumerID, url, "GET", {});
    //     if (response.data.code === 0) {
    //       return response.data.data;
    //     } else {
    //       throw Error(response.data.message);
    //     }
    //   } catch (err) {
    //     console.log(err);
    //     throw err;
    //   }
    // },
    // GetRules: async function (ConsumerID, url) {
    //   try {
    //     let response = await request(ConsumerID, url, "GET", {});
    //     if (response.data.code === 0) {
    //       return response.data.data;
    //     } else {
    //       throw Error(response.data.message);
    //     }
    //   } catch (err) {
    //     console.log(err);
    //     throw err;
    //   }
    // },
};
