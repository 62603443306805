import { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import MerchantServices from "../../services/merchant.service";

function UpdateBranchModalNew({ action, title, icon, props }) {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");
  const [merchants, setMerchants] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  useEffect(async () => {
    let merchants = await MerchantServices.GetRequest("/merchant");
    let branches = await MerchantServices.GetRequest(
      `/merchant/${merchants[0]._id}`
    );
    setMerchants(merchants);
    setBranches(branches);
    setSelectedMerchant(merchants[0]._id);
    setSelectedBranch(branches[0].name);
    setValue(branches[0].name);
  }, []);

  const onSubmit = async (evt) => {
    try {
      evt.preventDefault();

      await MerchantServices.UpdateBranch(
        selectedMerchant,
        selectedBranch,
        value
      );
      props.notify(
        "tr",
        "success",
        `${selectedBranch} салбарыг амжилттай заслаа!`
      );
      setValue(value);
      setShow(false);
    } catch (err) {
      props.notify("tr", "error", err.message);
    }
  };

  const onChangeMerchant = async (val) => {
    let branches = await MerchantServices.GetRequest(`/merchant/${val}`);

    if (branches.length > 0) {
      setValue(branches[0].name);
      setSelectedBranch(branches[0].name);
    } else {
      setValue("");
      setSelectedBranch([]);
    }

    setBranches(branches);
  };

  return (
    <div style={{ marginLeft: 10 }}>
      <Button onClick={handleShow} size="md">
        {icon}
        {action}
      </Button>

      <Modal
        style={{ fontSize: "13px" }}
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group>
              <Form.Label>Байгууллага сонгох</Form.Label>
              <Form.Control
                as="select"
                value={selectedMerchant}
                name="merchant"
                className="form-control"
                onChange={async (e) => {
                  await setSelectedMerchant(e.target.value);
                  await onChangeMerchant(e.target.value);
                }}
                required
              >
                {merchants.map((merchant, i) => (
                  <option key={i} value={merchant._id}>
                    {merchant.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Салбар сонгох</Form.Label>
              <Form.Control
                as="select"
                value={selectedBranch}
                name="branch"
                className="form-control"
                onChange={(e) => {
                  setSelectedBranch(e.target.value);
                  setValue(e.target.value);
                }}
                required
              >
                {branches.map((branches, i) => (
                  <option key={i} value={branches.name}>
                    {branches.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Салбарын нэр</Form.Label>
              <Form.Control
                name="name"
                type="text"
                as="input"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group>

            <Button type="submit" style={{ width: "100%" }}>
              Засах
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UpdateBranchModalNew;
