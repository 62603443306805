import { useEffect, useState } from "react";
import {
  Form,
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { Input } from "reactstrap";
import AsyncSelect from "react-select/async";
import TransactionService from "../services/transaction.service";
import Filter from "../components/Transaction/Filter";
import TransactionTotal from "../components/Transaction/TransactionTotal";
import ProcessTransaction from "../components/Transaction/ProcessTransaction";
import {
  formatPretty,
  getCurrentDate,
  getLastWeekDate,
} from "../helpers/dateHelper";
import Loading from "../components/Util/Loading";
import Pagination from "../components/Util/Pagination";
import TransactionDetailModal from "../components/Modal/TransactionDetailModal";
import { getPos } from "../services/mcp.service";
import { getScanType, getPurchases } from "../services/qrs.service";
import Select from "react-select";
import merchantService from "services/merchant.service";
import fileDownload from "js-file-download";

function Transactions(props) {
  const [total, setTotal] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [pointTransfers, setPointTransfers] = useState([]);
  const [startDate, setStartDate] = useState(getLastWeekDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [merchantList, setMerchantList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pointLoading, setPointLoading] = useState(false);
  const [chosenMerchant, setChosenMerchant] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [userType, setUserType] = useState("");

  const [receiptLoading, setReceiptLoading] = useState(false);
  const [receiptSearchValue, setReceiptSearch] = useState("");
  const [mcpPos, setMCPPos] = useState([]);
  const [selectedPos, setSelectedPos] = useState();
  const [mcpPosInput, setMCPPosInput] = useState();
  const [receiptStartDate, setReceiptStartDate] = useState(getCurrentDate());
  const [receiptEndDate, setReceiptEndDate] = useState(getCurrentDate());
  const [scanTypes, setScanTypes] = useState([{ value: -1, label: "Any" }]);
  const [selectedScanType, setSelectedScanType] = useState(-1);
  const [cols, setCols] = useState([]);
  const [receiptType, setReceiptType] = useState(0);

  const [receiptPageCount, setReceiptPageCount] = useState(0);
  const [receiptOffset, setReceiptOffset] = useState(0);

  const [pagination, setPagination] = useState();

  const getMCPPos = async (prefix = "") => {
    const terminals = await getPos(prefix);
    return terminals.data;
  };

  const getScanTypes = async () => {
    const scanTypes = await getScanType();
    return scanTypes.data;
  };

  useEffect(async () => {
    try {
      setMerchantList([]);
      setScanTypes(await getScanTypes());
      let merchants = await TransactionService.getFilterMerchants();
      setMerchantList(merchants);
      setMCPPos(await getMCPPos());
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setTransactions([]);
    setTotal(null);
    setChosenMerchant("");
    setTransactionType("");
    setUserType("");
  }, [props.consumer]);

  const loadOptions = async (inputValue, callback) => {
    const sth = await getMCPPos(inputValue);
    // console.log("loadOptions", sth);
    callback(sth);
  };

  const handleSearch = async () => {
    try {
      await getTransactions();
    } catch (err) {}
  };
  const pointhandleSearch = async () => {
    try {
      await getPointTransfer();
    } catch (err) {}
  };

  const handleDownload = async () => {
    try {
      await downloadTransactions();
    } catch (err) {}
  };

  const handleSearchTotal = async () => {
    try {
      await getTransactionsTotal();
    } catch (err) {}
  };

  const search1 = async (offset = 0) => {
    try {
      setReceiptLoading(true);
      setReceiptType(1);
      setReceiptOffset(offset);

      const result = await getPurchases(offset, 1, receiptSearchValue);
      // console.log(result.data.data);

      setCols(result.data.data);
      if (offset === 0) setReceiptPageCount(result.data.count);
    } catch (err) {
      console.log(err);
    } finally {
      setReceiptLoading(false);
    }
  };
  const search2 = async (offset = 0) => {
    try {
      setReceiptLoading(true);
      console.log("selectedScan", selectedScanType);
      setReceiptType(2);
      setReceiptOffset(offset);
      const result = await getPurchases(
        offset,
        2,
        undefined,
        selectedPos,
        receiptStartDate,
        receiptEndDate,
        selectedScanType
      );
      console.log("result data: ", result.data.data);
      setCols(result.data.data);
      if (offset === 0) setReceiptPageCount(result.data.count);
    } catch (err) {
      console.log(err);
    } finally {
      setReceiptLoading(false);
    }
  };

  const downloadTransactions = async (offset, limit) => {
    try {
      setLoading(true);
      let { fileName } = await TransactionService.downloadUserTransactions(
        props.consumer._id,
        startDate,
        endDate,
        chosenMerchant,
        transactionType,
        userType
      );
      console.log("fileName", fileName);
      let excel = await merchantService.getFile(fileName);
      fileDownload(excel, fileName);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getTransactions = async (offset, limit) => {
    try {
      setLoading(true);
      setTransactions([]);
      setPagination(null);
      let data = await TransactionService.getTransactions(
        props.consumer._id,
        startDate,
        endDate,
        chosenMerchant,
        transactionType,
        userType,
        offset,
        limit
      );
      console.log(data, "TRANSACTIONS");
      setTransactions(data.transactions);
      setPagination(data.pagination);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const getPointTransfer = async (offset, limit) => {
    try {
      setPointLoading(true);
      setPointTransfers([]);
      setPagination(null);
      let data = await TransactionService.getPointTransfer(
        props.consumer._id,
        startDate,
        endDate,
        offset,
        limit
      );
      setPointTransfers(data.transactions);
      setPagination(data.pagination);
    } catch (err) {
      console.log(err);
    } finally {
      setPointLoading(false);
    }
  };

  const getTransactionsTotal = async () => {
    try {
      setLoading(true);
      setTotal(null);
      let data = await TransactionService.getTransactionsTotal(
        props.consumer._id,
        startDate,
        endDate,
        chosenMerchant,
        transactionType,
        userType
      );
      // console.log(data);z
      setTotal(data);
    } catch (err) {
      props.notify("tr", "danger", err.message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  console.log("cols", cols);
  if (!props.consumer || !props.consumer.profile) {
    return (
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col
                    sm="8"
                    className="d-flex justify-content-between align-items-center mb-3"
                  >
                    <Card.Title as="h4">Баримтын түүх</Card.Title>
                  </Col>
                </Row>

                <Row>
                  <Col
                    md={2}
                    className="d-flex justify-content-between align-items-center mb-3"
                  >
                    <Input
                      style={{ height: "33px" }}
                      value={receiptSearchValue}
                      placeholder="Баримтын код"
                      onChange={({ target }) => {
                        setReceiptSearch(target.value);
                      }}
                    ></Input>
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      onClick={() => {
                        search1();
                      }}
                    >
                      Хайх
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    {mcpPos && (
                      <AsyncSelect
                        loadOptions={loadOptions}
                        onInputChange={(input) => {
                          setMCPPosInput(input);
                          return input;
                        }}
                        defaultOptions
                        cacheOptions
                        onChange={(target) => {
                          console.log(target.value);
                          setSelectedPos(target.value);
                        }}
                      ></AsyncSelect>
                    )}
                  </Col>
                  <Col md={2}>
                    <Input
                      value={receiptStartDate}
                      defaultValue={getCurrentDate()}
                      onChange={({ target }) => {
                        setReceiptStartDate(target.value);
                      }}
                      type="date"
                    ></Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      value={receiptEndDate}
                      defaultValue={getCurrentDate()}
                      onChange={({ target }) => {
                        setReceiptEndDate(target.value);
                      }}
                      type="date"
                    ></Input>
                  </Col>
                  <Col md={2}>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={scanTypes ? scanTypes[0] : null}
                      name="color"
                      options={scanTypes}
                      onChange={(selection) => {
                        console.log(selection);
                        setSelectedScanType(selection.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Button
                      onClick={() => {
                        search2();
                      }}
                    >
                      Хайх
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Loading loading={receiptLoading} />

                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Consumer_id</th>
                      <th>Consumer phone number</th>
                      <th>Баримтын код</th>
                      <th>bill id</th>
                      <th>terminal</th>
                      <th>code name</th>
                      <th>Хэвлэсэн огноо</th>
                      <th>Уншуулсан огноо</th>
                      <th>Баримтын төлөв</th>
                      <th>Оноо орсон огноо</th>
                      <th>Орсон нийт огноо</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cols && cols.length !== 0 ? (
                      cols.map((col, i) => {
                        return (
                          <tr>
                            <th>{i + 1}</th>
                            <td>
                              {col.consumer_id && col.consumer_id != []
                                ? col.consumer_id
                                : ""}
                            </td>
                            <td>
                              {col.mobile && col.mobile != [] ? col.mobile : ""}
                            </td>
                            <td>
                              {col.bill_number && col.bill_number != []
                                ? col.bill_number
                                : ""}
                            </td>

                            <td>
                              {col.bill_id && col.bill_id != []
                                ? col.bill_id
                                : ""}
                            </td>
                            <td>
                              {col.terminal && col.terminal != []
                                ? col.terminal
                                : ""}
                            </td>
                            <td>
                              {col.code_name && col.code_name != []
                                ? col.code_name
                                : ""}
                            </td>
                            <td>
                              {col.print_date && col.print_date != []
                                ? col.print_date
                                : ""}
                            </td>
                            <td>
                              {col.scan_date && col.scan_date != []
                                ? col.scan_date
                                : ""}
                            </td>
                            <td>
                              {col.status && col.status != [] ? col.status : ""}
                            </td>
                            <td>
                              {col.granted_date && col.granted_date != []
                                ? col.granted_date
                                : ""}
                            </td>
                            <td>
                              {col.total_point && col.total_point != []
                                ? col.total_point
                                : ""}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
                <div>
                  {!receiptLoading ? (
                    <Pagination
                      pagination={{
                        offset: receiptOffset,
                        count: receiptPageCount,
                        limit: 20,
                      }}
                      onChange={receiptType == 1 ? search1 : search2}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Оноо нөхөн олгох</Card.Title>
              </Card.Header>
              <Card.Body>
                <ProcessTransaction
                  consumer={props.consumer}
                  notify={props.notify}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col sm="8">
                    <Card.Title as="h4">Гүйлгээний түүх</Card.Title>
                  </Col>
                  <Col
                    sm="4"
                    className="d-flex justify-content-between align-items-center mb-3"
                  >
                    <Card.Title as="h4" className="mb-0">
                      Transaction Total
                    </Card.Title>
                    <Button
                      onClick={handleSearchTotal}
                      className="btn btn-sm"
                      size="md"
                    >
                      Хайх
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col sm="8">
                    <Filter
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      merchants={merchantList}
                      chosenMerchant={chosenMerchant}
                      setChosenMerchant={setChosenMerchant}
                      transactionType={transactionType}
                      setTransactionType={setTransactionType}
                      userType={userType}
                      setUserType={setUserType}
                      handleSearch={handleSearch}
                      handleSearchTotal={handleSearchTotal}
                      total={total}
                      handleDownload={handleDownload}
                    />
                  </Col>
                  <Col sm="4">
                    <TransactionTotal total={total} />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Created_at</th>
                      <th className="border-0">Date</th>
                      <th className="border-0">Mobile</th>
                      <th className="border-0">receipt id</th>
                      <th className="border-0">bill number</th>
                      <th className="border-0">merchant</th>
                      <th className="border-0">card number</th>
                      <th className="border-0">terminal</th>
                      <th className="border-0">Percentage</th>
                      <th className="border-0">total point</th>
                      <th className="border-0">spend amount</th>
                      <th className="border-0">total amount</th>
                      <th className="border-0">bonus amount</th>
                      <th className="border-0">merchant point</th>
                      <th className="border-0">items point</th>
                      <th className="border-0">before balance</th>
                      <th className="border-0">point balance</th>
                      <th className="border-0">is return</th>
                      <th className="border-0">family_id</th>
                      <th className="border-0">Univision SubId</th>
                    </tr>
                  </thead>

                  <tbody style={{ wordBreak: "break-all" }}>
                    {transactions?.map((transaction) => (
                      <>
                        {transaction?.receipt_return ? (
                          <>
                            <tr
                              key={transaction?.receipt_return?._id}
                              className={"table-return"}
                            >
                              <td>
                                {formatPretty(
                                  transaction?.receipt_return?.created_at
                                )}
                              </td>
                              <td></td>
                              <td>{transaction.mobile}</td>
                              <td>
                                <TransactionDetailModal
                                  receiptId={transaction?._id}
                                />
                              </td>
                              <td>
                                {transaction?.receipt_return?.bill_number || ""}
                              </td>
                              <td>{transaction.merchant_name || ""}</td>
                              <td>{transaction.card_number || ""}</td>
                              <td>{transaction.terminal}</td>
                              <td>
                                {(() => {
                                  const percent = Math.round(
                                    (transaction.total_point /
                                      transaction.bonus_amount) *
                                      100
                                  );
                                  console.log(percent);
                                  if (
                                    percent == Infinity ||
                                    (transaction.total_point == 0 &&
                                      transaction.bonus_amount == 0) ||
                                    transaction.return_receipt?._id
                                  ) {
                                    return <></>;
                                  }
                                  return percent + "%";
                                })()}
                              </td>
                              <td style={{ color: "green" }}>
                                <i className="fa fa-angle-up fa-lg"></i>
                                {transaction?.receipt_return?.total_point}
                              </td>
                              <td style={{ color: "red" }}>
                                <i className="fa fa-angle-down fa-lg"></i>
                                {
                                  transaction?.receipt_return
                                    ?.refund_spend_amount
                                }
                              </td>
                              <td>
                                {
                                  transaction?.receipt_return
                                    ?.refund_cash_amount
                                }
                              </td>
                              <td>
                                {
                                  transaction?.receipt_return
                                    ?.refund_bonus_amount
                                }
                              </td>
                              <td>
                                {transaction?.receipt_return?.merchant_point ||
                                  0}
                              </td>
                              <td>
                                {transaction?.receipt_return
                                  ?.manufacturer_items_point || 0}
                              </td>
                              <td>
                                {transaction?.receipt_return?.before_balance ||
                                  ""}
                              </td>
                              <td>
                                {transaction?.receipt_return?.point_balance}
                              </td>
                              <td>{transaction.is_return}</td>
                              <td>{transaction.family_id}</td>
                              <td>{""}</td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {transaction?.receipt_cancel ? (
                          <>
                            <tr
                              key={transaction?.receipt_cancel?._id}
                              className={"table-cancel"}
                            >
                              <td>
                                {formatPretty(
                                  transaction?.receipt_cancel?.created_at
                                )}
                              </td>
                              <td></td>
                              <td>{transaction.mobile}</td>
                              <td>
                                <TransactionDetailModal
                                  receiptId={transaction?._id}
                                />
                              </td>
                              <td>
                                {transaction?.receipt_cancel?.bill_number || ""}
                              </td>
                              <td>{transaction.merchant_name || ""}</td>
                              <td>{transaction.card_number || ""}</td>
                              <td>{transaction.terminal}</td>
                              <td>
                                {(() => {
                                  const percent = Math.round(
                                    (transaction.total_point /
                                      transaction.bonus_amount) *
                                      100
                                  );
                                  console.log(percent);
                                  if (
                                    percent == Infinity ||
                                    (transaction.total_point == 0 &&
                                      transaction.bonus_amount == 0) ||
                                    transaction?.receipt_cancel?._id
                                  ) {
                                    return <></>;
                                  }
                                  return percent + "%";
                                })()}
                              </td>
                              <td style={{ color: "green" }}>
                                <i className="fa fa-angle-up fa-lg"></i>
                                {transaction?.receipt_cancel?.total_point}
                              </td>
                              <td style={{ color: "red" }}>
                                <i className="fa fa-angle-down fa-lg"></i>
                                {
                                  transaction?.receipt_cancel
                                    ?.refund_spend_amount
                                }
                              </td>
                              <td>
                                {
                                  transaction?.receipt_cancel
                                    ?.refund_cash_amount
                                }
                              </td>
                              <td>
                                {
                                  transaction?.receipt_cancel
                                    ?.refund_bonus_amount
                                }
                              </td>
                              <td>
                                {transaction?.receipt_cancel?.merchant_point ||
                                  0}
                              </td>
                              <td>
                                {transaction?.receipt_cancel
                                  ?.manufacturer_items_point || 0}
                              </td>
                              <td>
                                {transaction?.receipt_cancel?.before_balance ||
                                  ""}
                              </td>
                              <td>
                                {transaction?.receipt_cancel?.point_balance}
                              </td>
                              <td>{transaction.is_return}</td>
                              <td>{transaction.family_id}</td>
                              <td>{""}</td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        <tr
                          key={transaction._id}
                          className={
                            transaction.is_return == 1 ? "table-danger" : ""
                          }
                        >
                          <td>{formatPretty(transaction.created_at)}</td>
                          <td>{formatPretty(transaction?.date)}</td>
                          <td>{transaction.mobile}</td>
                          <td>
                            <TransactionDetailModal
                              receiptId={transaction._id}
                            />
                          </td>
                          <td>{transaction.bill_number || ""}</td>
                          <td>{transaction.merchant_name || ""}</td>
                          <td>{transaction.card_number || ""}</td>
                          <td>{transaction.terminal}</td>
                          <td>
                            {(() => {
                              const percent = Math.round(
                                (transaction.total_point /
                                  transaction.bonus_amount) *
                                  100
                              );
                              console.log(percent);
                              if (
                                percent == Infinity ||
                                (transaction.total_point == 0 &&
                                  transaction.bonus_amount == 0) ||
                                transaction.return_receipt?._id
                              ) {
                                return <></>;
                              }
                              return percent + "%";
                            })()}
                          </td>
                          <td style={{ color: "green" }}>
                            <i className="fa fa-angle-up fa-lg"></i>
                            {transaction.total_point}
                          </td>
                          <td style={{ color: "red" }}>
                            <i className="fa fa-angle-down fa-lg"></i>
                            {transaction.spend_amount}
                          </td>
                          <td>{transaction.total_amount}</td>
                          <td>{transaction.bonus_amount}</td>
                          <td>{transaction.merchant_point}</td>
                          <td>{transaction.manufacturer_items_point}</td>
                          <td>{transaction.before_balance || ""}</td>
                          <td>{transaction.point_balance}</td>
                          <td>{transaction.is_return}</td>
                          <td>{transaction.family_id}</td>
                          <td>{transaction.univision_subid || ""}</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
                <Loading loading={loading} />
                <Pagination
                  pagination={pagination}
                  onChange={getTransactions}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col sm="8">
                    <Card.Title as="h4">Шилжүүлэгийн түүх</Card.Title>
                  </Col>
                </Row>

                <Row>
                  <Col sm="8">
                    <Form>
                      <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                          <Form.Label>Эхлэх огноо</Form.Label>
                          <Form.Control
                            type="date"
                            format="yyyy-mm-dd"
                            value={startDate}
                            onChange={(e) => {
                              setStartDate(e.target.value);
                            }}
                          />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridPassword">
                          <Form.Label>Дуусах огноо</Form.Label>
                          <Form.Control
                            type="date"
                            format="yyyy-mm-dd"
                            placeholder={endDate || "дуусах огноо"}
                            value={endDate}
                            onChange={(e) => {
                              setEndDate(e.target.value);
                            }}
                          />
                        </Form.Group>
                      </Form.Row>
                    </Form>
                    <Button
                      variant="primary"
                      className="btn btn-sm"
                      size="md"
                      onClick={pointhandleSearch}
                    >
                      Хайх
                    </Button>
                  </Col>
                  <Col sm="4">
                    <TransactionTotal total={total} />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Created_at</th>
                      <th className="border-0">receipt id</th>
                      <th className="border-0">note</th>
                      <th className="border-0">amount</th>
                      <th className="border-0">total point</th>
                      <th className="border-0">before balance</th>
                      <th className="border-0">point balance</th>
                      <th className="border-0">mobile</th>
                      <th className="border-0">merchant</th>
                      <th className="border-0">is_family</th>
                      <th className="border-0">family_id</th>
                      <th className="border-0">is return</th>
                    </tr>
                  </thead>

                  <tbody style={{ wordBreak: "break-all" }}>
                    {pointTransfers.map((transaction) => (
                      <tr key={transaction_id}>
                        <td>{formatPretty(transaction.created_at)}</td>
                        <td>
                          <TransactionDetailModal receiptId={transaction} />
                        </td>
                        <td>{transaction.note}</td>
                        <td>{transaction.amount || ""}</td>
                        <td>{transaction.total_point}</td>
                        <td>{transaction.before_balance}</td>
                        <td>{transaction.point_balance}</td>
                        <td>{transaction.mobile}</td>
                        <td>{transaction.merchant}</td>
                        <td>{transaction.is_family || ""}</td>
                        <td>{transaction.family_id}</td>
                        <td>{transaction.is_return}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Loading loading={pointLoading} />
                <Pagination
                  pagination={pagination}
                  onChange={getPointTransfer}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col
                    sm="8"
                    className="d-flex justify-content-between align-items-center mb-3"
                  >
                    <Card.Title as="h4">Баримтын түүх</Card.Title>
                  </Col>
                </Row>

                <Row>
                  <Col
                    md={2}
                    className="d-flex justify-content-between align-items-center mb-3"
                  >
                    <Input
                      style={{ height: "33px" }}
                      value={receiptSearchValue}
                      placeholder="Баримтын код"
                      onChange={({ target }) => {
                        setReceiptSearch(target.value);
                      }}
                    ></Input>
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      onClick={() => {
                        search1();
                      }}
                    >
                      Хайх
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    {mcpPos && (
                      <AsyncSelect
                        loadOptions={loadOptions}
                        onInputChange={(input) => {
                          setMCPPosInput(input);
                          return input;
                        }}
                        defaultOptions
                        cacheOptions
                        onChange={(target) => {
                          console.log(target.value);
                          setSelectedPos(target.value);
                        }}
                      ></AsyncSelect>
                    )}
                  </Col>
                  <Col md={2}>
                    <Input
                      value={receiptStartDate}
                      defaultValue={getCurrentDate()}
                      onChange={({ target }) => {
                        setReceiptStartDate(target.value);
                      }}
                      type="date"
                    ></Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      value={receiptEndDate}
                      defaultValue={getCurrentDate()}
                      onChange={({ target }) => {
                        setReceiptEndDate(target.value);
                      }}
                      type="date"
                    ></Input>
                  </Col>
                  <Col md={2}>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={scanTypes ? scanTypes[0] : null}
                      name="color"
                      options={scanTypes}
                      onChange={(selection) => {
                        console.log(selection);
                        setSelectedScanType(selection.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Button
                      onClick={() => {
                        search2();
                      }}
                    >
                      Хайх
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Loading loading={receiptLoading} />

                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Consumer_id</th>
                      <th>Consumer phone number</th>
                      <th>Баримтын код</th>
                      <th>bill id</th>
                      <th>terminal</th>
                      <th>code name</th>
                      <th>Хэвлэсэн огноо</th>
                      <th>Уншуулсан огноо</th>
                      <th>Баримтын төлөв</th>
                      <th>Оноо орсон огноо</th>
                      <th>Орсон нийт огноо</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cols && cols.length !== 0 ? (
                      cols.map((col, i) => {
                        return (
                          <tr>
                            <th>{i + 1}</th>
                            <td>
                              {col.consumer_id && col.consumer_id != []
                                ? col.consumer_id
                                : ""}
                            </td>
                            <td>
                              {col.mobile && col.mobile != [] ? col.mobile : ""}
                            </td>
                            <td>
                              {col.bill_number && col.bill_number != []
                                ? col.bill_number
                                : ""}
                            </td>

                            <td>
                              {col.bill_id && col.bill_id != []
                                ? col.bill_id
                                : ""}
                            </td>
                            <td>
                              {col.terminal && col.terminal != []
                                ? col.terminal
                                : ""}
                            </td>
                            <td>
                              {col.code_name && col.code_name != []
                                ? col.code_name
                                : ""}
                            </td>
                            <td>
                              {col.print_date && col.print_date != []
                                ? col.print_date
                                : ""}
                            </td>
                            <td>
                              {col.scan_date && col.scan_date != []
                                ? col.scan_date
                                : ""}
                            </td>
                            <td>
                              {col.status && col.status != [] ? col.status : ""}
                            </td>
                            <td>
                              {col.granted_date && col.granted_date != []
                                ? col.granted_date
                                : ""}
                            </td>
                            <td>
                              {col.total_point && col.total_point != []
                                ? col.total_point
                                : ""}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
                <div>
                  {!receiptLoading ? (
                    <Pagination
                      pagination={{
                        offset: receiptOffset,
                        count: receiptPageCount,
                        limit: 20,
                      }}
                      onChange={receiptType == 1 ? search1 : search2}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Transactions;
