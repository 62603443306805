import { Row, Col, Card, Table } from "react-bootstrap";
import Loading from "components/Util/Pagination";

import { useState, useEffect } from "react";
import Pagination from "components/Util/Pagination";
import EclinicService from "services/eclinic.service";

export default function Eclinic(props) {
  const [actions, setActions] = useState([]);
  const [promos, setPromos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState();
  const [Promopagination, setPromoPagination] = useState();

  useEffect(async () => {
    setActions([]);
    setLoading(false);
    await searchData();
  }, [props.consumer]);

  const searchData = async (offset, limit) => {
    try {
      setLoading(true);
      setActions([]);
      setPagination(null);
      let data;
      //   console.log("consumerId", props.consumer._id);
      data = await EclinicService.getEclinicAllData(
        limit,
        offset,
        props.consumer._id
      );
      setActions(data.data.result);
      setPagination(data.data.pagination);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  if (!props.consumer || !props.consumer.profile) {
    return <h4>Хэрэглэгчийн мэдээлэл байхгүй байна. Хэрэглэгч хайнаа уу</h4>;
  }
  return (
    <>
      {" "}
      <Col md="12">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">E Clinic</Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Table
              className="table-hover"
              style={{
                fontSize: "12px",
              }}
            >
              <thead>
                <tr>
                  <th className="border-0">№</th>
                  <th className="border-0">id</th>
                  <th className="border-0">РД</th>
                  <th className="border-0">Утасны дугаар</th>
                  <th className="border-0">Даалгаварын сар</th>
                  <th className="border-0">Үзлэгийн тоо</th>
                  <th className="border-0">Үнэлгээ</th>
                  <th className="border-0">Үзлэгийн нийт үнийн дүн</th>
                  <th className="border-0">Биелүүлсэн даалгавар</th>
                  <th className="border-0">Урамшууллын хувь</th>
                  <th className="border-0">Урамшууллын тоо</th>
                </tr>
              </thead>
              <tbody style={{ wordBreak: "break-all" }}>
                {actions?.map((action, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{action.consumer_id}</td>
                    <td>{action.register}</td>
                    <td>{action.consumer_mobile}</td>
                    <td>{action.month}</td>
                    <td>{action.progress}</td>
                    <td>{action.rate}</td>
                    <td>{action.total}</td>
                    <td>{action.mission_progress}</td>
                    <td>{action.persentage}</td>
                    <td>{action.bonus_point}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Loading loading={loading} />
            <Pagination pagination={pagination} onChange={searchData} />
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
