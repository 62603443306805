import { useEffect, useState } from "react";
import { Card, Table, Container, Row, Col } from "react-bootstrap";
import LotteryService from "../services/lottery.service";
import {
  formatPretty,
  getCurrentDate,
  getLastWeekDate,
} from "../helpers/dateHelper";
import Loading from "../components/Util/Loading";
import Pagination from "../components/Util/Pagination";
import Filter from "../components/Lottery/Filter";
import IssueTicket from "../components/Lottery/IssueTicket";

function Lottery(props) {
  if (!props.consumer || !props.consumer.profile) {
    return (
      <h4>Хэрэглэгчийн мэдээлэл байхгүй байна. Эхлээд хэрэглэгчээ хайна уу!</h4>
    );
  }
  const [tickets, setTickets] = useState([]);
  const [startDate, setStartDate] = useState(getLastWeekDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [promotions, setPromotions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chosenPromotion, setChosenPromotion] = useState("");

  useEffect(async () => {
    try {
      setPromotions([]);
      let promos = await LotteryService.getPromos("LOTTERY");
      setChosenPromotion(promos[0]._id);
      setPromotions(promos);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    // getSpins()
    setTickets([]);
  }, [props.consumer]);

  const handleSearch = async () => {
    try {
      await getTickets();
    } catch (err) {}
  };

  const getTickets = async (offset, limit) => {
    try {
      setLoading(true);
      setTickets([]);
      let data = await LotteryService.getConsumerTickets(
        props.consumer._id,
        startDate,
        endDate,
        chosenPromotion
      );
      console.log(data);
      setTickets(data.tickets);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Код нөхөн олгох</Card.Title>
              </Card.Header>
              <Card.Body>
                <IssueTicket consumer={props.consumer} notify={props.notify} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Сугалаа</Card.Title>
                <Row>
                  <Col md="8">
                    <Filter
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      promotions={promotions}
                      setChosenPromotion={setChosenPromotion}
                      chosenPromotion={chosenPromotion}
                      handleSearch={handleSearch}
                    />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">created at</th>
                      <th className="border-0">Bill number</th>
                      <th className="border-0">Grant Channel</th>
                      <th className="border-0">Status</th>
                      <th className="border-0">Код</th>
                      <th className="border-0">Дугаар</th>
                      <th className="border-0">Note</th>
                    </tr>
                  </thead>

                  <tbody style={{ wordBreak: "break-all" }}>
                    {tickets.map((ticket) => (
                      <tr key={ticket._id}>
                        <td>
                          {ticket?.created_at
                            ? formatPretty(
                                new Date(
                                  new Date(ticket?.created_at).getTime() +
                                    8 * 60 * 60 * 1000
                                )
                              )
                            : ""}
                        </td>
                        <td>{ticket.bill_number}</td>
                        <td>{ticket.grant_channel}</td>
                        <td>{ticket.status}</td>
                        <td>{ticket.ticket_number}</td>
                        <td>{ticket.batch}</td>
                        <td>{ticket.issue_note || "<ХООСОН>"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Loading loading={loading} />
                {/* <Pagination pagination={pagination} onChange={getTransactions} /> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Lottery;
