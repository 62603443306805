import request from "./request.service";
import axios from "axios";
export default {
  getMukhlagConsumerData: async function (phone_num, reg_num, month) {
    try {
      let response = await request(
        `/mukhlag/data?phone_number=${phone_num}&reg_num=${reg_num}&month=${month}`
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
};
