import { useEffect, useState } from "react";
import { Card, Table, Container, Row, Col } from "react-bootstrap";
import PromoService from "../services/promo.service";
import {
  formatPretty,
  getCurrentDate,
  getLastWeekDate,
} from "../helpers/dateHelper";
import Loading from "../components/Util/Loading";
import Pagination from "../components/Util/Pagination";
import Filter from "../components/Spin/Filter";
import IssueSpin from "../components/Spin/IssueSpin";

function Spin(props) {
  if (!props.consumer || !props.consumer.profile) {
    return (
      <h4>Хэрэглэгчийн мэдээлэл байхгүй байна. Эхлээд хэрэглэгчээ хайна уу!</h4>
    );
  }
  const styleGreen = { color: "green" };
  const styleRed = { color: "red" };
  const [spins, setSpins] = useState([]);
  const [startDate, setStartDate] = useState(getLastWeekDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [promotions, setPromotions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chosenPromotion, setChosenPromotion] = useState("");

  useEffect(async () => {
    try {
      setPromotions([]);
      let promos = await PromoService.getSpinPromotions();
      setChosenPromotion(promos[0]._id);
      setPromotions(promos);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    // getSpins()
    setSpins([]);
  }, [props.consumer]);

  const handleSearch = async () => {
    try {
      await getSpins();
    } catch (err) {}
  };

  const getSpins = async (offset, limit) => {
    try {
      setLoading(true);
      setSpins([]);
      let data = await PromoService.getConsumerSpins(
        props.consumer._id,
        startDate,
        endDate,
        chosenPromotion
      );
      setSpins(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Эрх нөхөн олгох</Card.Title>
              </Card.Header>
              <Card.Body>
                <IssueSpin consumer={props.consumer} notify={props.notify} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Хүрд</Card.Title>
                <Row>
                  <Col md="8">
                    <Filter
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      promotions={promotions}
                      setChosenPromotion={setChosenPromotion}
                      chosenPromotion={chosenPromotion}
                      handleSearch={handleSearch}
                    />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">created at</th>
                      <th className="border-0">Bill number</th>
                      <th className="border-0">Receipt ID</th>
                      <th className="border-0">Авсан төрөл</th>
                      <th className="border-0">Урамшууллын нэр</th>
                      <th className="border-0">Эргүүлсэн эсэх</th>
                      <th className="border-0">Эргүүлсэн огноо</th>
                      <th className="border-0">Хожсон шагнал</th>
                      <th className="border-0">Хожсон оноо</th>
                      <th className="border-0">Оноо олгосон Bill number</th>
                    </tr>
                  </thead>

                  <tbody style={{ wordBreak: "break-all" }}>
                    {spins.map((spin) => (
                      <tr key={spin._id}>
                        <td>{formatPretty(spin.created_at)}</td>
                        <td>{spin.bill_number}</td>
                        <td>{spin.receipt}</td>
                        <td>
                          {!!spin.bill_number.match(/^[A-Z]+_/i)
                            ? "PURCHASED"
                            : "TRANSACTION"}
                        </td>
                        <td>{spin.promotion && spin.promotion.promo_name}</td>
                        <td style={spin.is_spinned ? styleGreen : styleRed}>
                          {spin.is_spinned ? "эргүүлсэн" : "эргүүлээгүй"}
                        </td>
                        <td>
                          {spin.spinned_at ? formatPretty(spin.spinned_at) : ""}
                        </td>
                        <td>{spin.picked_choice && spin.picked_choice.name}</td>
                        <td>{spin.picked_choice && spin.win_point}</td>
                        <td>{spin.spin_bill_number}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Loading loading={loading} />
                {/* <Pagination pagination={pagination} onChange={getTransactions} /> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Spin;
