import { formatBeauty } from "helpers/dateHelper";
import { Card, Table } from "react-bootstrap";
import GenericModal from "../Modal/GenericModal";
import Loading from "../Util/Loading";
import ChangePasswordModal from "../Modal/ChangePasswordModal";
import RestService from "../../services/rest.service";
import { userUnblock } from "../../services/auth.service";
import { useContext, useEffect } from "react";
import AppContext from "store/AppContext";
function EmployeeList(props) {
  const appContext=useContext(AppContext)
  if (!props.employees || props.employees.length === 0) {
    return (
      <Card>
        <Card.Header>
          <Card.Title as="h4">Ажилчид</Card.Title>
        </Card.Header>
        <Card.Body>
          Одоогоор системд бүртгэгдсэн хэрэглэгч байхгүй байна.
        </Card.Body>
      </Card>
    );
  }
  const styleGreen = { color: "green" };
  const styleRed = { color: "red" };
  useEffect(() => {
    // console.log("employes", props.employees);
  });
  const deleteUser = async ({ username }) => {
    try {
      console.log("role:",appContext.currentUser)
      if (appContext.currentUser.role == "admin") {
        await RestService.deleteUser(username);
        props.notify("tr", "success", `${username} хэрэглэгчийг устгалаа!`);
        props.refreshEmployees();
      }
    } catch (err) {
      props.notify(
        "tr",
        "danger",
        `${username} хэрэглэгчийг устгахад алдаа гарлаа!!`
      );
      console.log(err);
    }
    console.log("delete", username);
  };

  const unblockUser = async ({ username }) => {
    try {
      await userUnblock(username, props.currentUser.access_token);
      props.notify(
        "tr",
        "success",
        `${username} хэрэглэгчийг амжилттай UNBLOCK хийлээ.`
      );
    } catch (err) {
      props.notify("tr", "danger", `${err.message}`);
      console.log(err);
    }
  };

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center mb-3">
        <Card.Title as="h4" className="mb-0">
          Ажилчид
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table
          className="table-hover table-striped"
          style={{
            fontSize: "12px",
          }}
        >
          <thead>
            <tr>
              <th className="border-0">Хэрэглэгчийн нэр</th>
              <th className="border-0">Овог</th>
              <th className="border-0">Нэр</th>
              <th className="border-0">Email</th>
              <th className="border-0">Утасны дугаар</th>
              <th className="border-0">Албан тушаал</th>
              <th className="border-0">Role</th>
              <th className="border-0">Регистрийн дугаар</th>
              <th className="border-0">Системд нэвтэрсэн огноо</th>
              <th className="border-0">Блок гаргах</th>
              <th className="border-0">Устгах</th>
            </tr>
          </thead>
          <tbody>
            {props.employees.map((employee) => (
              <tr key={employee._id}>
                <td>{employee.username}</td>
                <td>{employee.last_name}</td>
                <td>{employee.first_name || ""}</td>
                <td>{employee.email}</td>
                <td>{employee.phone_number || ""}</td>
                <td>{employee.position || ""}</td>
                <td>{employee.role || ""}</td>
                <td>{employee.registration_number || ""}</td>
                <td>{formatBeauty(employee.last_login_date) || ""}</td>

                <td>
                  <GenericModal
                    onClick={unblockUser}
                    params={{ username: employee.username }}
                    action={"Block гаргах"}
                    title={"Хэрэглэгчийн блок гаргах"}
                    body={`Та ${employee.username} хэрэглэгчийн блок гаргах уу?`}
                  />
                </td>
                <td>
                  <GenericModal
                    onClick={deleteUser}
                    params={{ username: employee.username }}
                    action={"Устгах"}
                    title={"Хэрэглэгч устгах"}
                    body={`Та ${employee.username} хэрэглэгчийн эрхийг устгахдаа итгэлтэй байна уу?`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Loading loading={props.loading} />
      </Card.Body>
    </Card>
  );
}
export default EmployeeList;
