import request from "./request.service";

export default {
  getConsumerInfo: async function (consumer_id) {
    try {
      const response = await request("/lunar2024/consumer_info", "POST", {
        consumer_id: consumer_id.toString(),
      });
      // console.log(response.data, "CONSUMERINFO");
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  getGameLog: async function (consumer_id, offset, limit, startDate, endDate) {
    try {
      const response = await request("/lunar2024/game_log", "POST", {
        consumer_id: consumer_id.toString(),
        limit: limit,
        offset: offset,
        start_date: startDate,
        end_date: endDate,
      });
      // console.log(response.data, "GAMELOG");
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  getTokenBoost: async function (
    consumer_id,
    offset = 0,
    limit = 10,
    startDate,
    endDate
  ) {
    try {
      const response = await request("/lunar2024/token_boost", "POST", {
        consumer_id: consumer_id.toString(),
        limit: limit,
        offset: offset,
        start_date: startDate,
        end_date: endDate,
      });
      //   console.log(response.data, "TOKENBOOST");
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  AaruulCollect: async function (consumer_id, offset, limit) {
    try {
      const response = await request("/lunar2024/collect_aaruul", "POST", {
        consumer_id: `${consumer_id}`,
        limit: limit,
        offset: offset,
      });
      // console.log(response.data, "COLLECTAARUUL");
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  getAaruulTransaction: async function (consumer_id) {
    try {
      const response = await request("/lunar2024/aaruul", "POST", {
        consumer_id: `${consumer_id}`,
      });
      // console.log(response.data, "SPENTAARUUL");
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  getMission: async function (consumer_id) {
    try {
      const response = await request("/lunar2024/mission", "POST", {
        consumer_id: `${consumer_id}`,
      });
      // console.log(response.data, "CONSUMERMISSION");
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  getposition: async function (consumer_id) {
    try {
      const response = await request("/lunar2024/leaderBoard", "POST", {
        consumer_id: `${consumer_id}`,
      });
      // console.log(response.data, "POSITION");
      return response.data;
    } catch (err) {
      throw err;
    }
  },
};
