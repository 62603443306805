import request from "./request.service";

export default {
  general: async function (consumer_id) {
    try {
      const response = await request(
        `/partner2024/general_info/${consumer_id}`,
        "GET",
        {}
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  boxCollect: async function (consumer_id, offset, limit) {
    try {
      const response = await request("/partner2024/box_collect", "POST", {
        consumer_id: consumer_id,
        offset: offset,
        limit: limit,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  boxSpend: async function (consumer_id, offset, limit) {
    try {
      const response = await request("/partner2024/box_spend", "POST", {
        consumer_id: consumer_id,
        offset: offset,
        limit: limit,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  spinCollect: async function (consumer_id, offset, limit) {
    try {
      const response = await request("/partner2024/spin_collect", "POST", {
        consumer_id: consumer_id,
        offset: offset,
        limit: limit,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  spinSpend: async function (consumer_id, offset, limit) {
    try {
      const response = await request("/partner2024/spin_spend", "POST", {
        consumer_id: consumer_id,
        offset: offset,
        limit: limit,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  mission: async function (consumer_id) {
    try {
      const response = await request(`/partner2024/mission/${consumer_id}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  giveBox: async function (consumer_id, count, type, note) {
    try {
      const response = await request("/partner2024/give_box", "POST", {
        consumer_id: consumer_id,
        count: count,
        type: type,
        note: note,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  giveSpin: async function (consumer_id, count, note) {
    try {
      const response = await request("/partner2024/give_spin", "POST", {
        consumer_id: consumer_id,
        count: count,
        note: note,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  },
};
