import request from "./request.service";
import axios from "axios";
export default {
  searchConsumer: async function (searchValue) {
    try {
      const response = await request("/consumer/search", "POST", {
        search_value: searchValue,
      });

      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  searchConsumerByMobile: async function (search_value) {
    try {
      const response = await request("/consumer/search_by_mobile", "POST", {
        search_value: search_value,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  updateCardToActivate: async function (card_number) {
    try {
      const response = await request("/consumer/update_card_act", "POST", {
        card_number: card_number,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  updateCardToInactivate: async function (card_number) {
    try {
      const response = await request("/consumer/update_card_Inact", "POST", {
        card_number: card_number,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getConsumer: async function (consumer_id) {
    try {
      const response = await request(`/consumer/${consumer_id}`, "GET", {});
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },

  sendPin: async function (consumerId) {
    try {
      const response = await request("/consumer/send_pin", "POST", {
        consumer_id: consumerId.toString(),
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getPin: async function (phone_number) {
    try {
      const response = await request("/sms/send_pin", "POST", {
        search_value: phone_number,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  unBlockTAN: async function (mobile) {
    console.log("service mobile =>", mobile);
    try {
      const response = await request(`/check/tan/${mobile}/unblock`, "GET");
      console.log("unblock in service", response);
      if (response.data.code === 0) {
        return response.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  unBlockPin: async function (mobile) {
    try {
      const response = await request(`/check/tan/${mobile}/unblock_pin`, "GET");
      if (response.data.code === 0) {
        return response.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  checkActive: async function (mobile) {
    try {
      const response = await request(`/unitel/check_active`, "POST", {
        mobile: mobile,
      });
      if (response.data.code === 0) {
        return response.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  checkMigration: async function (mobile) {
    try {
      // console.log(mobile);
      const response = await request(`/consumer/check_migragion`, "POST", {
        mobile: `${mobile}`,
      });
      return response;
    } catch (err) {
      throw err;
    }
  },
};
