import { Row, Col, Table, Button, Card, Tab, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { getLastWeekDate } from "helpers/dateHelper";
import { getCurrentDate } from "helpers/dateHelper";
import Pagination from "components/Util/Pagination";
import moment from "moment";
import Lunar24Service from "services/Lunar24.service";
import Loading from "components/Util/Loading";
export default function Lunar2024(props) {
  const [playerInfo, setPlayerInfo] = useState();

  const [startDate, setStartDate] = useState(getLastWeekDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [gamestartDate, setgameStartDate] = useState(getLastWeekDate());
  const [gameendDate, setgameEndDate] = useState(getCurrentDate());

  const [collect, setCollect] = useState([]);
  const [collectPagination, setCollectPagination] = useState();

  const [aaruulPagination, setAaruulPagination] = useState();

  const [gamelog, setGamelog] = useState([]);
  const [gamePagination, setgamePagination] = useState();

  const [aaruulSpend, setAaruulSpent] = useState();
  const [aaruulCollect, setAaruulCollect] = useState();

  const [mission, setMission] = useState();
  const [position, setPosition] = useState();

  const [loading, setLoading] = useState(false);
  const getPlayerInfo = async () => {
    try {
      const result = await Lunar24Service.getConsumerInfo(props.consumer._id);
      setPlayerInfo({
        ...result.data.data,
        consumer_balance: result.data?.balance[0]?.total_balance,
        token_count: result?.data?.token_count,
        boost_count: result?.data?.boost_count,
        all_token_count: result?.data.all_token_count,
        all_boost_count: result?.data.all_boost_count,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getGames = async (offset = 0, limit = 10) => {
    try {
      setGamelog([]);
      const result = await Lunar24Service.getGameLog(
        props.consumer._id,
        offset,
        limit,
        new Date(gamestartDate).toISOString(),
        new Date(gameendDate).toISOString()
      );
      setGamelog(result.data.data);
      setgamePagination(result.data.pagination);
    } catch (err) {
      console.log(err);
    }
  };
  const getAaruulSpend = async () => {
    try {
      setAaruulSpent([]);
      const result = await Lunar24Service.getAaruulTransaction(
        props.consumer._id
      );
      setAaruulSpent(result.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getAaruulCollect = async (offset = 0, limit = 10) => {
    try {
      setAaruulCollect([]);
      const result = await Lunar24Service.AaruulCollect(
        props.consumer._id,
        offset,
        limit
      );
      setAaruulCollect(result.data.data);
      setAaruulPagination(result.data.pagination);
    } catch (err) {
      console.log(err);
    }
  };
  const getCollectTokenBoost = async (offset = 0, limit = 10) => {
    try {
      setCollect([]);
      const result = await Lunar24Service.getTokenBoost(
        props.consumer._id,
        offset,
        limit,
        new Date(startDate).toISOString(),
        new Date(endDate).toISOString()
      );
      setCollect(result.data.data);
      setCollectPagination(result.data.pagination);
    } catch (err) {
      console.log(err);
    }
  };
  const getConsumerMission = async () => {
    try {
      setMission([]);
      const result = await Lunar24Service.getMission(props.consumer._id);
      console.log(result, "MISSIONRESULT");
      setMission(result.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getPosition = async () => {
    try {
      setPosition([]);
      setLoading(true);
      const result = await Lunar24Service.getposition(props.consumer._id);
      console.log(result.data, "POSITION RESULT");
      setPosition(result.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPlayerInfo();
    getGames();
    getAaruulSpend();
    getAaruulCollect();
    getCollectTokenBoost();
    getConsumerMission();
    getPosition();
  }, [props?.consumer]);
  const handlerSearch = async () => {
    getPlayerInfo();
    getGames();
    getAaruulSpend();
    getAaruulCollect();
    getCollectTokenBoost();
    getConsumerMission();
    getPosition();
  };
  return (
    <>
      <Col md="12">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Хэрэглэгчийн одоогийн мэдээлэл</Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Row>
              <Col md="8">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Дугаар</th>
                      <th className="border-0">Үнээ</th>
                      <th className="border-0">Ааруулын тоо</th>
                      <th className="border-0">Тус үеийн Саасан сүү</th>
                      <th className="border-0">Онцгой өдрийн Саасан сүү</th>
                      <th className="border-0">Эрхийн үлдэгдэл тоо</th>
                      <th className="border-0">Бүүст үлдэгдэл тоо</th>
                      <th className="border-0">Нийт үүссэн Эрхийн тоо</th>
                      <th className="border-0">Нийт үүссэн Бүүст тоо</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{props?.consumer?.mobile}</td>
                      <td>{playerInfo?.cow}</td>
                      <td>{playerInfo?.aaruul}</td>
                      <td>{playerInfo?.balance}</td>
                      <td>{playerInfo?.special_balance}</td>
                      {/* <td>{playerInfo?.consumer_balance}</td> */}
                      <td>{playerInfo?.token_count}</td>
                      <td>{playerInfo?.boost_count}</td>
                      <td>{playerInfo?.all_token_count}</td>
                      <td>{playerInfo?.all_boost_count}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>

          <Form>
            <Form.Row className="align-items-center">
              <Form.Group as={Col} sm="3" controlId="formGridPassword">
                <Button
                  style={{ marginTop: "20px" }}
                  variant="primary"
                  className="btn btn-sm"
                  size="md"
                  onClick={handlerSearch}
                >
                  Хайх
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        </Card>
      </Col>
      <Col md="6">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">7 Хоногийн уралдаан</Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Row>
              <Col md="3">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <th className="border-0">Үе</th>
                  </thead>
                  <tbody>
                    {position?.map((obj, ind) => (
                      <tr key={ind}>
                        <td>
                          {obj.key} : {`${obj.pos}`}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>

              <Loading loading={loading} />
              {/* <Col md="3">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <th className="border-0">Үе</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td>4</td>
                    </tr>
                    <tr>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td>7</td>
                    </tr>
                  </tbody>
                </Table>
              </Col> */}
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col md="12">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Эрх Цуглуулалт</Card.Title>
          </Card.Header>
          <Form>
            <Form.Row className="align-items-center">
              <Form.Group as={Col} sm="2" controlId="formGridEmail">
                <Form.Label>Эхлэх огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} sm="2" controlId="formGridPassword">
                <Form.Label>Дуусах огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  placeholder={endDate || "дуусах огноо"}
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} sm="3" controlId="formGridPassword">
                <Button
                  style={{ marginTop: "20px" }}
                  variant="primary"
                  className="btn btn-sm"
                  size="md"
                  onClick={() => {
                    getCollectTokenBoost();
                  }}
                >
                  Хайх
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
          <Row>
            <Col md="8">
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th className="border-0">Огноо</th>
                    <th className="border-0">Олголт</th>
                    <th className="border-0">Төрөл</th>
                    <th className="border-0">Дугаар</th>
                    <th className="border-0">receipt дүн</th>
                    <th className="border-0">receipt id</th>
                    <th className="border-0">receipt date</th>
                    <th className="border-0">Баримтын дугаар</th>
                  </tr>
                </thead>
                <tbody style={{ wordBreak: "break-all" }}>
                  {collect?.map((log) => (
                    <tr>
                      <td>
                        {moment(log?.created_at).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td>{log?.grant_channel}</td>
                      <td>{log?.type}</td>
                      <td>{log?.number}</td>
                      <td>{log?.receipt_amount}</td>
                      <td>{log?.receipt_id}</td>
                      <td>
                        {moment(log?.receipt_date).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td>{log.barcode}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                pagination={collectPagination}
                onChange={getCollectTokenBoost}
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col md="12">
        <Card className="striped-table-with-hover">
          <Card.Header>
            <Card.Title as="h4">Эрх зарцуулалт /Тоглолтын түүх/</Card.Title>
          </Card.Header>
          <Form>
            <Form.Row className="align-items-center">
              <Form.Group as={Col} sm="2" controlId="formGridEmail">
                <Form.Label>Эхлэх огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  placeholder={gameendDate || "эхлэх огноо"}
                  value={gamestartDate}
                  onChange={(e) => {
                    setgameStartDate(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} sm="2" controlId="formGridPassword">
                <Form.Label>Дуусах огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  placeholder={gameendDate || "дуусах огноо"}
                  value={gameendDate}
                  onChange={(e) => {
                    setgameEndDate(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} sm="3" controlId="formGridPassword">
                <Button
                  style={{ marginTop: "20px" }}
                  variant="primary"
                  className="btn btn-sm"
                  size="md"
                  onClick={() => {
                    getGames();
                  }}
                >
                  Хайх
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
          <Row>
            <Col md="12">
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th className="border-0">Тоглолсон огноо</th>
                    <th className="border-0">Бүүст ашигласан эсэх</th>
                    <th className="border-0">Үржвэр</th>
                    <th className="border-0">Үеийн дугаар</th>
                    <th className="border-0">Тооцох даралтын тоо</th>
                    <th className="border-0">Үндсэн даралтын тоо</th>
                    <th className="border-0">Төрөл</th>
                    <th className="border-0">Саасан сүү</th>
                    <th className="border-0">Бонус төрөл</th>
                    <th className="border-0">Бонус Дэлгэрэнгүй</th>
                  </tr>
                </thead>
                <tbody style={{ wordBreak: "break-all" }}>
                  {gamelog?.map((game, ind) => (
                    <tr key={ind}>
                      <td>
                        {moment(game?.created_at).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td>{game?.is_use_boost == true ? "Тйим" : "Үгүй"}</td>

                      <td>{game?.multiplier}</td>
                      <td>{game?.phase_number}</td>
                      <td>{game?.tap_count}</td>
                      <td>{game?.tap_count_original}</td>
                      <td>{game?.type}</td>
                      <td>{game?.balance}</td>
                      <td>{game?.prize_type}</td>
                      <td>{game?.prize_description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Pagination pagination={gamePagination} onChange={getGames} />
        </Card>
      </Col>
      <Col md="12">
        <Card className="striped-table-with-hover">
          <Card.Header>
            <Card.Title as="h4">Ааруул цуглуулалт</Card.Title>
          </Card.Header>
          <Form>
            {/* <Form.Row className="align-items-center">
              <Form.Group as={Col} sm="2" controlId="formGridEmail">
                <Form.Label>Эхлэх огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  // value={startDate}
                  onChange={(e) => {
                    // setStartDate(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} sm="2" controlId="formGridPassword">
                <Form.Label>Дуусах огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  placeholder={endDate || "дуусах огноо"}
                  // value={endDate}
                  onChange={(e) => {
                    // setEndDate(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} sm="3" controlId="formGridPassword">
                <Button
                  style={{ marginTop: "20px" }}
                  variant="primary"
                  className="btn btn-sm"
                  size="md"
                  onClick={handleSearch}
                >
                  Хайх
                </Button>
              </Form.Group>
            </Form.Row> */}
          </Form>
          <Row>
            <Col md="12">
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th className="border-0">Тоглолсон огноо</th>
                    <th className="border-0">Үе</th>
                    <th className="border-0">Даралтын тоо</th>
                    <th className="border-0">Бүүст ашигласан</th>
                    <th className="border-0">Саасан сүү</th>
                    <th className="border-0">Төрөл</th>
                    <th className="border-0">Бонус</th>
                    <th className="border-0">Бонус Дэлгэрэнгүй</th>
                  </tr>
                </thead>
                <tbody style={{ wordBreak: "break-all" }}>
                  {aaruulCollect?.map((log, ind) => (
                    <tr key={ind}>
                      <td>
                        {moment(log?.created_at).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td>{log?.phase_number}</td>
                      <td>{log?.tap_count_original}</td>
                      <td>{log?.is_use_boost == true ? "Тийм" : "Үгүй"}</td>
                      <td>{log?.balance}</td>
                      <td>{log?.type}</td>
                      <td>{log?.prize_type}</td>

                      <td>{log?.prize_description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Pagination
            pagination={aaruulPagination}
            onChange={getAaruulCollect}
          />
        </Card>
      </Col>
      <Col md="12">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Ааруул Зарцуулалт</Card.Title>
          </Card.Header>

          {/* <Form>
            <Form.Row className="align-items-center">
              <Form.Group as={Col} sm="2" controlId="formGridEmail">
                <Form.Label>Эхлэх огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  placeholder={mintstartDate || "эхлэх огноо"}
                  value={mintstartDate}
                  onChange={(e) => {
                    setmintStartDate(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} sm="2" controlId="formGridPassword">
                <Form.Label>Дуусах огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  placeholder={mintendDate || "дуусах огноо"}
                  value={mintendDate}
                  onChange={(e) => {
                    setmintEndDate(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} sm="3" controlId="formGridPassword">
                <Button
                  style={{ marginTop: "20px" }}
                  variant="primary"
                  className="btn btn-sm"
                  size="md"
                  onClick={getAaruulSpend}
                >
                  Хайх
                </Button>
              </Form.Group>
            </Form.Row>
          </Form> */}
          <Row>
            <Col md="8">
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th className="border-0">Үүссэн огноо</th>
                    <th className="border-0">Купон</th>
                    <th className="border-0">Зарцуулсан ааруул</th>
                    <th className="border-0">Өмнөх ааруул</th>
                    <th className="border-0">Үлдэгдэл ааруул</th>
                  </tr>
                </thead>
                <tbody style={{ wordBreak: "break-all" }}>
                  {aaruulSpend?.map((log, ind) => (
                    <tr key={ind}>
                      <td>
                        {moment(log?.created_at).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td>{log?.coupon_name}</td>
                      <td>{log?.spent_aaruul}</td>
                      <td>{log?.before_aaruul}</td>
                      <td>{log?.after_aaruul}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {/* <Pagination
                pagination={aaruulspendPagination}
                onChange={getAaruulSpend}
              /> */}
            </Col>
          </Row>
        </Card>
      </Col>
      <Col md="12">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Даалгавар</Card.Title>
          </Card.Header>

          <Row>
            <Col md="8">
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th className="border-0">Үүссэн огноо</th>
                    <th className="border-0">Нэр</th>
                    <th className="border-0">Биелэсэн эссэх</th>
                    <th className="border-0">Биелэсэн огноо</th>
                    <th className="border-0">Бонус</th>
                    <th className="border-0">Дэлгэрэнгүй</th>
                  </tr>
                </thead>
                <tbody style={{ wordBreak: "break-all" }}>
                  {mission?.map((conmi, ind) => (
                    <tr key={ind}>
                      <td>
                        {moment(conmi?.created_at).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td>{conmi?.name}</td>
                      <td>{conmi?.is_completed == true ? "Тийм" : "Үгүй"}</td>
                      <td>
                        {conmi?.is_completed == true
                          ? moment(conmi?.completed_at).format(
                              "YYYY-MM-DD HH:mm"
                            )
                          : ""}
                      </td>
                      <td>{conmi?.bonus}</td>
                      <td>{conmi?.description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
}
