import { Container, Row, Col } from "react-bootstrap";
import ActiveBadges from "../components/Badge/ActiveBadges";
import History from "../components/Badge/History";

function Badge(props) {
  if (!props.consumer|| !props.consumer.profile ) {
    return (
      <h4>Хэрэглэгчийн мэдээлэл байхгүй байна. Эхлээд хэрэглэгчээ хайна уу!</h4>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="5">
            <ActiveBadges {...props} />
          </Col>
          <Col md="7">
            <History {...props} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Badge;
