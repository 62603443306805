import { useState, useEffect } from "react";
import {
  Container,
  Card,
  Table,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import BlockSealService from "../services/blockSeal.service";
import Loading from "../components/Util/Loading";
import { formatPretty } from "../helpers/dateHelper";
import ConsumerInfo from "../components/Consumer/ConsumerInfo";
import GenericModalNote from "../components/Modal/GenericModalNote";

function Seal(props) {
  if (!props.consumer || !props.consumer.profile) {
    return <h4>Хэрэглэгчийн мэдээлэл байхгүй байна. Хэрэглэгч хайна уу!</h4>;
  }

  const [loading, setLoading] = useState(false);
  const [sealList, setSealList] = useState([]);
  const styleGreen = { color: "green" };
  const styleRed = { color: "red" };
  const getSeals = async () => {
    try {
      setLoading(true);
      setSealList([]);
      let seals = await BlockSealService.getSealedList(props.consumer._id);
      setSealList(seals);
      if (seals.length === 0) {
        props.notify("tr", "danger", `Битүүмж мэдээлэл олдсонгүй.`);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const unseal = async ({ receiptId, note }) => {
    try {
      console.log(receiptId, note);
      let response = await BlockSealService.unseal(receiptId, note);
      console.log(response);
      props.notify(
        "tr",
        "success",
        `${receiptId} id тай гүйлгээний битүүмжийг гаргалаа.`
      );
      await getSeals();
    } catch (err) {
      props.notify("tr", "danger", err.message);
    }
  };

  useEffect(() => {
    setSealList([]);
    setLoading(false);
  }, [props.consumer]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <ConsumerInfo consumer={props.consumer} />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="striped-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Битүүмж лист</Card.Title>
                <Form>
                  <Form.Row className="align-items-center">
                    <Form.Group as={Col} sm="3" controlId="formGridPassword">
                      <Button
                        variant="primary"
                        className="btn btn-sm"
                        size="md"
                        onClick={getSeals}
                      >
                        ХАЙХ
                      </Button>
                    </Form.Group>
                  </Form.Row>
                </Form>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Огноо</th>
                      <th className="border-0">Receipt ID</th>
                      <th className="border-0">Bill number</th>
                      <th className="border-0">Карт дугаар</th>
                      <th className="border-0">Bonus amount</th>
                      <th className="border-0">Total amount</th>
                      <th className="border-0">Total point</th>
                      <th className="border-0">Merchant point</th>
                      <th className="border-0">Spend amount</th>
                      <th className="border-0">Items point</th>
                      <th className="border-0">Merchant</th>
                      <th className="border-0">Terminal</th>
                      <th className="border-0">Төлөв</th>
                      <th className="border-0">Гаргасан огноо</th>
                      <th className="border-0">Тайлбар</th>
                      <th className="border-0">Ажилтан</th>
                      <th className="border-0">Битүүмж гаргах</th>
                    </tr>
                  </thead>
                  {sealList.length === 0 ? (
                    <tbody>
                      <tr>
                        <td align="center" colSpan="17">
                          <h6>Битүүмжийн мэдээлэл байхгүй байна.</h6>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody style={{ wordBreak: "break-all" }}>
                      {sealList.map((seal, key) => (
                        <tr key={key}>
                          <td>{formatPretty(seal.created_at)}</td>
                          <td>{seal.receipt_id}</td>
                          <td>{seal.bill_number}</td>
                          <td>{seal.card_number}</td>
                          <td>{seal.bonus_amount}</td>
                          <td>{seal.total_amount}</td>
                          <td>{seal.total_point}</td>
                          <td>{seal.merchant_point}</td>
                          <td>{seal.spend_amount}</td>
                          <td>{seal.items_point}</td>
                          <td>{seal.merchant_name}</td>
                          <td>{seal.terminal}</td>
                          <td
                            style={
                              seal.seal_status === 1 ? styleRed : styleGreen
                            }
                          >
                            {seal.seal_status === 1
                              ? "Битүүмжлэгдсэн"
                              : "Битүүмжнээс гарсан"}
                          </td>
                          <td>
                            {seal.unseal_date
                              ? formatPretty(seal.unseal_date)
                              : ""}
                          </td>
                          <td>{seal.description}</td>
                          <td>{seal.employee}</td>
                          {seal.seal_status === 1 ? (
                            <td>
                              <GenericModalNote
                                onClick={unseal}
                                params={{
                                  receiptId: seal.receipt_id,
                                }}
                                action={"Битүүмж гаргах"}
                                title={"Битүүмж гаргах"}
                                body={`Та ${props.consumer.mobile} дугаартай хэрэглэгчийн ${seal.bill_number} гүйлгээний битүүжийг гаргахдаа итгэлтэй байна уу?`}
                              />
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
                <Loading loading={loading} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Seal;
