import React, { useState, useContext } from 'react'
import { Container, Button, Card, Form, Row, Col } from 'react-bootstrap'
import RestService from '../services/rest.service'
import AppContext from '../store/AppContext'

function ChangePassword(props) {
    const initialState = {
        password: '',
        new_password: '',
        confirmation_password: '',
    }

    const [state, setState] = useState(initialState)
    const appContext = useContext(AppContext)

    const onChange = (evt) => {
        const value = evt.target.value
        setState({
            ...state,
            [evt.target.name]: value,
        })
    }
    const onSubmit = async (evt) => {
        try {
            evt.preventDefault()
            await RestService.changePassword(state)
            setState(initialState)
            appContext.notify('tr', 'success', `Таны нууц үг амжилттай солигдсон`)
        } catch (err) {
            // props.showAlert('danger', err.message)
            appContext.notify('tr', 'danger', err.message)
        }
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md="6">
                        <Card>
                            <Card.Header>
                                <Card.Title as="h4">Нууц үг солих</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={onSubmit}>
                                    <Form.Group as={Row} controlId="formPassword">
                                        <Form.Label column sm="3">
                                            Одоогийн нууц үг
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control
                                                type="text"
                                                placeholder="Одоогийн нууц үг"
                                                name="password"
                                                type="password"
                                                value={state.password}
                                                onChange={onChange}
                                                required
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formNewPassword">
                                        <Form.Label column sm="3">
                                            Шинэ нууц үг
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control
                                                type="text"
                                                placeholder="Шинэ нууц үг"
                                                name="new_password"
                                                type="password"
                                                value={state.new_password}
                                                onChange={onChange}
                                                required
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formConfirmationPassword">
                                        <Form.Label column sm="3">
                                            Нууц үг давтан оруулна уу
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control
                                                type="text"
                                                placeholder="Шинэ нууц үг"
                                                name="confirmation_password"
                                                type="password"
                                                value={state.confirmation_password}
                                                onChange={onChange}
                                                required
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Button className="float-right" variant="primary" type="submit" size="sm">
                                        Нууц үг солих
                                    </Button>
                                    {/* </div> */}
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ChangePassword
