import { useEffect, useState, useRef } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "./../components/Sidebar/Sidebar";
import routes from "routes.js";
import NotFound from "components/Util/404";
import Alert from "components/Util/Alert";
import sidebarImage from "assets/img/sidebar-5.jpg";

function Admin(props) {
  const [image, setImage] = useState("");
  const [color, setColor] = useState("black");
  const [hasImage, setHasImage] = useState(true);
  const location = useLocation();
  const mainPanel = useRef(null);

  const [alert, setAlert] = useState({
    show: false,
    text: "",
    variant: "info",
  });
  const hideAlert = () => {
    setAlert((prevState) => ({
      ...prevState,
      show: false,
    }));
  };
  const showAlert = (variant, text) => {
    setAlert({ show: true, text: text, variant: variant });
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  const routes_ = routes
    .filter((route) => {
      if (
        (route.layout === "/admin" &&
          props.currentUser.views.includes(route.view)) ||
        route.view === "change_password"
      ) {
        return true;
      } else {
        return false;
      }
    })
    .map((prop, index) => {
      return (
        <Route
          exact
          path={prop.layout + prop.path}
          component={prop.component}
          key={index}
        >
          <prop.component {...props} key={index} showAlert={showAlert} />
        </Route>
      );
    });

  const firstRoute = routes_[0];

  return (
    <>
      <div className="wrapper">
        <Sidebar
          color={color}
          image={hasImage ? image : ""}
          routes={routes}
          views={props.currentUser.views}
        />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar username={props.currentUser.sub} />
          <div className="content">
            <Alert {...alert} hideAlert={hideAlert} />
            <Switch>
              {routes_}
              <Route path="/admin/404" component={NotFound} />
              <Redirect from="/admin" to={firstRoute.props.path} />
              <Redirect from="*" to="/admin/404" />
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Admin;
