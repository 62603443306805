import { Button, Form, Row, Col, Card, Table } from "react-bootstrap";
import { getCurrentDate, getLastWeekDate } from "../helpers/dateHelper";
import { useState, useEffect, useContext } from "react";
import Select from "react-select";

import AppContext from "store/AppContext";
import Loading from "components/Util/Pagination";

import mukhlagService from "services/mukhlag.service";
export default function GroseryStores(props) {
  const options = [
    {
      label: "2023/05",
      value: 2305,
    },
    {
      label: "2023/06",
      value: 2306,
    },
    {
      label: "2023/07",
      value: 2307,
    },
    {
      label: "2023/08",
      value: 2308,
    },
    {
      label: "2023/09",
      value: 2309,
    },
    {
      label: "2023/10",
      value: 2310,
    },
    {
      label: "2023/11",
      value: 2311,
    },
    {
      label: "2023/12",
      value: 2312,
    },
  ];
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(2305);
  useEffect(async () => {
    setActions([]);
    setLoading(false);
    await searchLog();
  }, [props.consumer]);

  const searchLog = async (offset, limit) => {
    try {
      setLoading(true);
      setActions([]);
      let data;
      data = await mukhlagService.getMukhlagConsumerData(
        props.consumer.mobile,
        props.consumer.registration_number,
        date
      );
      setActions(data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  if (!props.consumer || !props.consumer.profile) {
    return (
      <h4>Хэрэглэгчийн мэдээлэл байхгүй байна. Эхлээд хэрэглэгчээ хайнаа уу</h4>
    );
  }
  return (
    <>
      {" "}
      <Col md="12">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">
              Бүтээгдэхүүний эрхийн бичиг ашигласан мэдээлэл
            </Card.Title>
          </Card.Header>
          <Form>
            <Form.Row>
              <Form.Group as={Col} sm="2" controlId="formGridPassword">
                <Form.Label>Хугацаа</Form.Label>
                <Select
                  defaultValue={{
                    value: "2305",
                    label: "2023/05",
                  }}
                  onChange={(e) => {
                    setDate(e.value);
                  }}
                  options={options}
                ></Select>
              </Form.Group>
              <Form.Group as={Col} sm="3" controlId="formGridPassword">
                <Button
                  style={{ marginTop: "30px" }}
                  variant="primary"
                  className="btn btn-sm"
                  size="md"
                  onClick={searchLog}
                >
                  Хайх
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
          <Card.Body className="table-full-width table-responsive px-0">
            <Row>
              <Col md="7">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">id</th>
                      <th className="border-0">Created at</th>
                      <th className="border-0">U-Point код</th>
                      <th className="border-0">Дэлгүүрийн мэдээлэл</th>
                      <th className="border-0">Эрх үүссэн төрөл</th>
                    </tr>
                  </thead>
                  <tbody style={{ wordBreak: "break-all" }}>
                    {actions.consumer_scan?.map((action, key) => (
                      <tr key={key}>
                        <td>{action._id}</td>
                        <td>{action.created_at}</td>
                        <td>{action.upoint_code}</td>
                        <td>{action.merchant_token}</td>
                        <td>{action.is_qr ? "QR" : "Гараар"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
              <Col md="5">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Тохирлын код</th>
                      <th className="border-0">Болзол</th>
                      <th className="border-0">Сонгосон бүтээгдэхүүн</th>
                      <th className="border-0">Сонгосон огноо</th>
                    </tr>
                  </thead>
                  <tbody style={{ wordBreak: "break-all" }}>
                    {actions.all_tickets?.map((action, key) => (
                      <tr>
                        <td>{action.ticket_number}</td>
                        <td>{action.tier}</td>
                        <td>{action.received_product_id.name}</td>
                        <td>{action.created_at}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Loading loading={loading} />
          </Card.Body>
        </Card>
      </Col>
      <Col md="12">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Купоны мэдээлэл</Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Table
              className="table-hover"
              style={{
                fontSize: "12px",
              }}
            >
              <thead>
                <tr>
                  <th className="border-0">Үүссэн огноо</th>
                  <th className="border-0">Купоны нэр</th>
                  <th className="border-0">Дуусах огноо</th>
                  <th className="border-0">Ашигласан эсэх</th>
                  <th className="border-0">Ашигласан огноо</th>
                  <th className="border-0">Ашигласан ажилтан </th>
                  <th className="border-0">Идэвхтэй эсэх</th>
                </tr>
              </thead>
              <tbody style={{ wordBreak: "break-all" }}></tbody>
            </Table>
            <Loading loading={loading} />
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
