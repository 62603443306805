import React, { useEffect } from "react";
import {
  Navbar,
  Container,
  Nav,
  Button,
  Form,
  Dropdown,
} from "react-bootstrap";
import ConsumerSearch from "../Consumer/ConsumerSearch";
import { authService } from "../../services/auth.service";
import { NavLink } from "react-router-dom";
function Header(props) {
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <ConsumerSearch />
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" navbar>
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                aria-expanded={false}
                aria-haspopup={true}
                as={Nav.Link}
                data-toggle="dropdown"
                variant="default"
                className="m-0"
                style={{ minWidth: "150px" }}
              >
                <span className="no-icon">{props.username}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
                <NavLink
                  className="dropdown-item"
                  to={"/admin/change_password"}
                  activeClassName="hehe"
                >
                  <i className="fa fa-key" aria-hidden="true"></i> Нууц үг солих
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to={"/admin/settings"}
                  activeClassName="hehe"
                >
                  <i className="fa fa-cog" aria-hidden="true"></i> Тохиргоо
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to={"/admin/employee_log"}
                  activeClassName="hehe"
                >
                  <i className="fa fa-history" aria-hidden="true"></i> Лог
                </NavLink>
                <div className="divider"></div>
                <Dropdown.Item
                  href=""
                  style={{ color: "red" }}
                  onClick={(e) => {
                    e.preventDefault();
                    authService.logout();
                  }}
                >
                  <i className="fa fa-arrow-left" aria-hidden="true"></i> Гарах
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
