import { useState, useEffect } from "react";
import { Card, Table, Col, Button, Form, Row } from "react-bootstrap";
import LogService from "../../services/consumer_log.service";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import AppContext from "store/AppContext";
import {
  formatBeauty,
  getCurrentDate,
  getLastWeekDate,
} from "../../helpers/dateHelper";
import { useContext } from "react";

function ReceivedSMS(props) {
  const [startDate, setStartDate] = useState(getLastWeekDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [smsList, setSMSList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState();
  const appContext = useContext(AppContext);
  useEffect(() => {
    setSMSList([]);
    setStartDate(getLastWeekDate());
    setEndDate(getCurrentDate());
    setLoading(false);
  }, [props.consumer]);

  const handleSearch = async () => {
    try {
      await searchSMS();
      appContext.notify("tr", "danger", "Хайх үйлдэлийн лог хадгалагдлаа.");
    } catch (err) {}
  };

  const searchSMS = async (offset, limit) => {
    try {
      setLoading(true);
      setSMSList([]);
      setPagination(null);
      let data = await LogService.getReceivedSMSList(
        props.consumer.mobile,
        startDate,
        endDate,
        offset,
        limit
      );
      // console.log(data);
      setSMSList(data.data);
      setPagination(data.pagination);
      console.log("pagination", data.pagination);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Col md="8"></Col>

      <Col md="12">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Хэрэглэгчийн илгээсэн мессежүүд </Card.Title>
            <Form>
              <Form.Row className="align-items-center">
                <Form.Group as={Col} sm="2" controlId="formGridEmail">
                  <Form.Label>Эхлэх огноо</Form.Label>
                  <Form.Control
                    type="date"
                    format="yyyy-mm-dd"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group as={Col} sm="2" controlId="formGridPassword">
                  <Form.Label>Дуусах огноо</Form.Label>
                  <Form.Control
                    type="date"
                    format="yyyy-mm-dd"
                    placeholder={endDate || "дуусах огноо"}
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} sm="3" controlId="formGridPassword">
                  <Button
                    style={{ marginTop: "20px" }}
                    variant="primary"
                    className="btn btn-sm"
                    size="md"
                    onClick={handleSearch}
                  >
                    Хайх
                  </Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Table
              className="table-hover"
              style={{
                fontSize: "12px",
              }}
            >
              <thead>
                <tr style={{ wordBreak: "break-word" }}>
                  <th className="border-0">Огноо</th>
                  <th className="border-0">Дугаар</th>
                  <th className="border-0">Текст</th>
                  <th className="border-0">Хариу</th>
                </tr>
              </thead>
              <tbody style={{ wordBreak: "break-all" }}>
                {smsList.map((sms, key) => (
                  <tr key={key}>
                    <td>
                      {formatBeauty(
                        new Date(
                          new Date(sms.created_at).getTime() -
                            8 * 60 * 60 * 1000
                        )
                      )}
                    </td>
                    <td>{sms.mobile}</td>
                    <td>{sms.text}</td>
                    <td>{sms.response}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Loading loading={loading} />
            <Pagination pagination={pagination} onChange={searchSMS} />
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
export default ReceivedSMS;
