import { Alert } from 'react-bootstrap'
function CustomAlert({ show, variant, text, hideAlert }) {
    if (!show) {
        return null
    }
    return (
        <Alert className="alert-with-icon" variant={variant}>
            <button aria-hidden={true} className="close" data-dismiss="alert" type="button" onClick={hideAlert}>
                <i className="nc-icon nc-simple-remove"></i>
            </button>
            <span data-notify="icon" className="nc-icon nc-bell-55"></span>
            <span>{text}</span>
        </Alert>
    )
}
export default CustomAlert
