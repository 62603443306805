import request from "./request.service";
export default {
    getFilterRoles: async function () {
        try {
            const response = await request("/filter/role", "GET", {});
            if (response.data.code === 0) {
                return response.data.data;
            } else {
                throw Error(response.data.message);
            }
        } catch (err) {
            throw err;
        }
    },
    getFilterFamilyEvents: async function () {
        try {
            const response = await request("/filter/family_event", "GET", {});
            if (response.data.code === 0) {
                return response.data.data;
            } else {
                throw Error(response.data.message);
            }
        } catch (err) {
            throw err;
        }
    },

    createUser: async function (body) {
        try {
            const response = await request("/admin/create_user", "POST", body);
            if (response.data.code === 0) {
                return response.data.data;
            } else {
                throw Error(response.data.message);
            }
        } catch (err) {
            throw err;
        }
    },
    deleteUser: async function (username) {
        try {
            const response = await request("/admin/delete_user", "POST", {
                username: username,
            });
            if (response.data.code === 0) {
                return response.data.data;
            } else {
                throw Error(response.data.message);
            }
        } catch (err) {
            throw err;
        }
    },
    toggleUserStatus: async function (username) {
        try {
            const response = await request("/admin/toggle_status", "POST", {
                username: username,
            });
            if (response.data.code === 0) {
                return response.data.data;
            } else {
                throw Error(response.data.message);
            }
        } catch (err) {
            throw err;
        }
    },
    setPassword: async function (username, password) {
        try {
            const response = await request("/admin/set_password", "POST", {
                username,
                password,
            });
            if (response.data.code === 0) {
                return response.data.data;
            } else {
                throw Error(response.data.message);
            }
        } catch (err) {
            throw err;
        }
    },
    changePassword: async function (body) {
        try {
            const response = await request(
                "/auth/change_password",
                "POST",
                body
            );
            if (response.data.code === 0) {
                return response.data.data;
            } else {
                throw Error(response.data.message);
            }
        } catch (err) {
            throw err;
        }
    },

    getUsers: async function () {
        try {
            const response = await request("/admin/users", "GET", {});
            if (response.data.code === 0) {
                return response.data.data;
            } else {
                throw Error(response.data.message);
            }
        } catch (err) {
            throw err;
        }
    },
};
