import ReactPaginate from "react-paginate";

function Pagination({ pagination, onChange }) {
    if (!pagination || pagination.count == 0) {
        return null;
    }
    return (
        <ReactPaginate
            previousLabel={"Өмнөх"}
            nextLabel={"Дараах"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(pagination.count / pagination.limit)}
            marginPagesDisplayed={3}
            pageRangeDisplayed={5}
            forcePage={Math.ceil(pagination.offset / pagination.limit)}
            onPageChange={(data) => {
                let offset = data.selected * pagination.limit;
                console.log(offset, pagination.limit);
                onChange(offset, pagination.limit);
            }}
            containerClassName={"pagination justify-content-center"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            pageClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
            pageLinkClassName={"page-link"}
            disabledClassName={"disabled"}
        />
    );
}

export default Pagination;
