import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import LogService from "services/log.service";
import { formatBeauty } from "helpers/dateHelper";

function Log(props) {
  const [mobile, setMobile] = useState();
  const [loading, setLoading] = useState(false);
  const [checkTerminate, setCheckTerminate] = useState(null);
  const checkMobile = async () => {
    try {
      setLoading(true);
      setCheckTerminate(null);
      const response = await LogService.getTerminateNumber(mobile);
      console.log("response:", response);
      setCheckTerminate(response);
    } catch (err) {
      props.notify("tr", "danger", err.message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const [terminateNumber, setTerminateNumber] = useState({});
  console.log("props", props.consumer?.mobile);
  const getTerminateNumber = async () => {
    try {
      if (props.consumer?.mobile) {
        const result = await LogService.getTerminateNumber(
          props.consumer?.mobile
        );
        console.log(result);
        setTerminateNumber(result);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(async () => {
    await getTerminateNumber();
  }, [props.consumer?.mobile]);

  if (!props.consumer || !props.consumer.mobile) {
    return (
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Terminate</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col sm="3">
                      <Form>
                        <Form.Row className="align-items-center">
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Дугаар</Form.Label>
                            <Form.Control
                              type="text"
                              value={mobile}
                              onChange={(e) => {
                                setMobile(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            sm="3"
                            controlId="formGridPassword"
                          >
                            <Button
                              style={{ marginTop: "20px" }}
                              variant="primary"
                              className="btn btn-sm"
                              size="md"
                              onClick={checkMobile}
                            >
                              Шалгах
                            </Button>
                          </Form.Group>
                        </Form.Row>
                      </Form>
                    </Col>
                    <Col sm="9">
                      <Table
                        className="table-hover"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        <thead>
                          <tr>
                            <th className="border-0">Terminate огноо</th>
                            <th className="border-0">Хуучин дугаар</th>
                            <th className="border-0">Өөрчлөгдсөн Дугаар</th>
                            <th className="border-0">Terminate үлдэгдэл</th>
                            <th className="border-0">Үлдэгдэл</th>
                          </tr>
                        </thead>
                        {checkTerminate === null ? null : (
                          <tbody style={{ wordBreak: "break-all" }}>
                            <tr>
                              <td>{formatBeauty(checkTerminate.date)}</td>
                              <td>{checkTerminate.old_number}</td>
                              <td>{checkTerminate.new_number}</td>
                              <td>{checkTerminate.terminate_balance}</td>
                              <td>{checkTerminate.balance}</td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <>
      <Container fluid>
        <Form>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Terminate</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <Row>
                      <Col sm="3">
                        <Form>
                          <Form.Row className="align-items-center">
                            <Form.Group as={Col} controlId="formGridEmail">
                              <Form.Label>Дугаар</Form.Label>
                              <Form.Control
                                type="text"
                                value={mobile}
                                onChange={(e) => {
                                  setMobile(e.target.value);
                                }}
                              />
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              sm="3"
                              controlId="formGridPassword"
                            >
                              <Button
                                style={{ marginTop: "20px" }}
                                variant="primary"
                                className="btn btn-sm"
                                size="md"
                                onClick={checkMobile}
                              >
                                Шалгах
                              </Button>
                            </Form.Group>
                          </Form.Row>
                        </Form>
                      </Col>
                      <Col sm="9">
                        <Table
                          className="table-hover"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          <thead>
                            <tr>
                              <th className="border-0">Terminate огноо</th>
                              <th className="border-0">Хуучин дугаар</th>
                              <th className="border-0">Өөрчлөгдсөн Дугаар</th>
                              <th className="border-0">Terminate үлдэгдэл</th>
                              <th className="border-0">Үлдэгдэл</th>
                            </tr>
                          </thead>
                          {checkTerminate === null ? null : (
                            <tbody style={{ wordBreak: "break-all" }}>
                              <tr>
                                <td>{formatBeauty(checkTerminate.date)}</td>
                                <td>{checkTerminate.old_number}</td>
                                <td>{checkTerminate.new_number}</td>
                                <td>{checkTerminate.terminate_balance}</td>
                                <td>{checkTerminate.balance}</td>
                              </tr>
                            </tbody>
                          )}
                        </Table>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {terminateNumber ? (
            <Row>
              <Col md="12">
                <Card className="striped-tabled-with-hover">
                  <Card.Header>
                    <Card.Title as="h4">Terminate дугаар шалгах</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Date:</Form.Label>
                          <Form.Control
                            type="text"
                            name="Date"
                            value={formatBeauty(terminateNumber.date)}
                            readOnly
                          ></Form.Control>
                        </Form.Group>

                        <Col></Col>
                        <Form.Group>
                          <Form.Label>Event ID:</Form.Label>
                          <Form.Control
                            type="input"
                            name="Date"
                            value={terminateNumber.event_id}
                            readOnly
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Хуучин дугаар:</Form.Label>
                          <Form.Control
                            type="text"
                            name="Date"
                            value={terminateNumber.old_number}
                            readOnly
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Registration number:</Form.Label>
                          <Form.Control
                            type="text"
                            name="Date"
                            value={terminateNumber.registration_number}
                            readOnly
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Last name:</Form.Label>
                          <Form.Control
                            type="text"
                            name="Date"
                            value={terminateNumber.last_name}
                            readOnly
                          ></Form.Control>
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group>
                          <Form.Label>First name:</Form.Label>
                          <Form.Control
                            type="text"
                            name="Date"
                            value={terminateNumber.first_name}
                            readOnly
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Онооны үлдэгдэл:</Form.Label>
                          <Form.Control
                            type="text"
                            name="Date"
                            value={terminateNumber.balance}
                            readOnly
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Form>

        {/* {props?.consumer?.profile ? (
                    <Row>
                        <LogComponent consumer={props.consumer} />
                    </Row>
                ) : (
                    <></>
                )}
                {props?.consumer?.mobile ? (
                    <Row>
                        <SentSMS consumer={props.consumer} />
                    </Row>
                ) : (
                    <></>
                )}
                {props?.consumer?.mobile ? (
                    <Row>
                        <ReceivedSMS consumer={props.consumer} />
                    </Row>
                ) : (
                    <></>
                )} */}
      </Container>
    </>
  );
}

export default Log;
