import { useEffect, useState } from "react";
import { Card, Col, Row, Table, Form } from "react-bootstrap";
import Pagination from "components/Util/Pagination";
import partner24Service from "services/partner24.service";
import GenericModal from "../components/Modal/GenericModal";
export default function Partner2024(props) {
  //paginations
  const [boxCollectPagination, setboxCollectPagination] = useState();
  const [boxSpendPagination, setboxSpendPagination] = useState();
  const [spinCollectPagination, setspinCollectPagination] = useState();
  const [spinSpendPagination, setspinSpendPagination] = useState();
  //data
  const [box_collect, setboxCollect] = useState([]);
  const [box_spend, setboxSpend] = useState([]);

  const [spin_collect, setspinCollect] = useState([]);
  const [spin_spend, setspinSpend] = useState([]);

  const [general, setGeneral] = useState({});
  const [mission, setMission] = useState([]);

  const [spinState, setSpinState] = useState({ note: "", count: 0 });
  const [boxState, setBoxState] = useState({
    note: "",
    box: "HAPPY",
    count: 0,
  });
  const [status, setStatus] = useState(false);
  const issueSpinChance = async () => {
    try {
      console.log("ISSUE SPIN CALLED", spinState);
      const result = await partner24Service.giveSpin(
        props?.consumer?._id,
        spinState.count,
        spinState.note
      );
      if (result.code === 0) {
        props.notify("tr", "success", `Амжилттай.`);
        setStatus(!status);
        setSpinState({ note: "", count: 0 });
      } else {
        props.notify("tr", "danger", `Алдаа гарлаа.`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const issueBox = async () => {
    try {
      console.log("ISSUE BOX CALLED", boxState);
      const result = await partner24Service.giveBox(
        props?.consumer._id,
        boxState.count,
        boxState.box,
        boxState.note
      );
      if (result.code === 0) {
        props.notify("tr", "success", `Амжилттай.`);
        setBoxState({ note: "", count: 0, box: "HAPPY" });
        setStatus(!status);
      } else {
        props.notify("tr", "danger", `Алдаа гарлаа.`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onChangeSp = async (event) => {
    setSpinState({ ...spinState, [event.target.name]: event.target.value });
  };
  const onChangeBox = async (event) => {
    setBoxState({ ...boxState, [event.target.name]: event.target.value });
  };
  const generalInfo = async () => {
    try {
      if (props.consumer) {
        const result = await partner24Service.general(props?.consumer?._id);
        setGeneral(result?.data);
        // setboxCollectPagination({});
        // setboxSpendPagination({});
        // setspinCollectPagination({});
        // setspinSpendPagination({});
        // setboxCollectPagination((prevState) => ({
        //   ...prevState,
        //   count: result?.data?.all_box ? result?.data?.all_box : 0,
        // }));
        // setboxSpendPagination((prevState) => ({
        //   ...prevState,
        //   count: result?.data?.all_used_box ? result?.data?.all_used_box : 0,
        // }));
        // setspinCollectPagination((prevState) => ({
        //   ...prevState,
        //   count: result?.data?.all_spin ? result?.data?.all_spin : 0,
        // }));
        // setspinSpendPagination((prevState) => ({
        //   ...prevState,
        //   count: result?.data?.all_used_spin ? result?.data?.all_used_spin : 0,
        // }));
      }
    } catch (err) {
      console.log("general_error");
    }
  };
  const getBoxCollect = async (offset = 0, limit = 10) => {
    try {
      if (props.consumer._id) {
        const result = await partner24Service.boxCollect(
          props?.consumer._id,
          offset,
          limit
        );
        setboxCollect(result.data);

        setboxCollectPagination((prevState) => ({
          ...prevState,
          offset: offset,
          limit: limit,
          count: general?.all_box,
        }));
      }
    } catch (err) {
      console.log("box_collect_error");
    }
  };
  const getBoxSpend = async (offset = 0, limit = 10) => {
    try {
      console.log("THISISBOXSPEND");
      if (props.consumer._id) {
        const result = await partner24Service.boxSpend(
          props?.consumer._id,
          offset,
          limit
        );
        console.log(result, "asdfasdf");
        setboxSpend(result.data);
        setboxSpendPagination((prevState) => ({
          ...prevState,
          offset: offset,
          limit: limit,
          count: general?.all_used_box,
        }));
      }
    } catch (err) {
      console.log("box_spend_error");
    }
  };
  const getSpinCollect = async (offset = 0, limit = 10) => {
    try {
      if (props.consumer._id) {
        const result = await partner24Service.spinCollect(
          props?.consumer._id,
          offset,
          limit
        );
        console.log(result.data, "SPINCOLLECT");
        setspinCollect(result.data);
        setspinCollectPagination((prevState) => ({
          ...prevState,
          offset: offset,
          limit: limit,
          count: general?.all_spin,
        }));
      }
    } catch (err) {
      console.log("spin_error");
    }
  };
  const getSpinSpend = async (offset = 0, limit = 10) => {
    try {
      if (props.consumer._id) {
        const result = await partner24Service.spinSpend(
          props?.consumer._id,
          offset,
          limit
        );
        setspinSpend(result.data);
        setspinSpendPagination((prevState) => ({
          ...prevState,
          offset: offset,
          limit: limit,
          count: general?.all_used_spin,
        }));
      }
    } catch (err) {
      console.log("spin_spend_error");
    }
  };
  const getMission = async () => {
    try {
      const result = await partner24Service.mission(props?.consumer._id);
      setMission(result.data);
    } catch (err) {
      console.log("mission_error");
    }
  };
  useEffect(() => {
    generalInfo();
  }, []);

  useEffect(() => {
    if (props?.consumer) {
      generalInfo();
      getBoxCollect();
      getBoxSpend();
      getSpinCollect();
      getSpinSpend();
      getMission();
    }
  }, [props?.consumer]);
  return (
    <>
      <Row>
        <Col md="6">
          <Card className="striped-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Хэрэглэгч Box</Card.Title>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Col md="12">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Нийт цуглуулсан box эрх</th>
                      <th className="border-0">Зарцуулсан эрхийн тоо</th>
                      <th className="border-0">Ашиглах боломжтой эрхийн тоо</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{general?.all_box}</td>
                      <td>{general?.all_used_box}</td>
                      <td>{general?.all_act_box}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6">
          <Card className="striped-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Хүрд</Card.Title>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Col md="12">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Нийт цуглуулсан хүрдний эрх</th>
                      <th className="border-0">Зарцуулсан хүрдний тоо</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{general?.all_spin}</td>
                      <td>{general?.all_used_spin}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row md="12">
        <Col md="6">
          <Card className="striped-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Box эрх олгох</Card.Title>
            </Card.Header>

            <Card.Body>
              <Form>
                <Row>
                  <Col md="8">
                    <Form.Group controlId="formGridPassword">
                      <Form.Label>Box</Form.Label>
                      <Form.Control
                        as="select"
                        value={boxState.box}
                        name={"box"}
                        className="form-control"
                        onChange={(e) => {
                          setBoxState({ ...boxState, box: e.target.value });
                        }}
                      >
                        <option key={0} value={"HAPPY"}>
                          HAPPY
                        </option>
                        <option key={1} value={"SUPER"}>
                          SUPER
                        </option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formGridEmail">
                      <Form.Label>Нөхөн олгох эрхийн тоо</Form.Label>
                      <Form.Control
                        type="number"
                        name={"count"}
                        value={boxState.count}
                        onChange={onChangeBox}
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Тайлбар</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Тайлбар"
                        value={boxState.note}
                        name="note"
                        onChange={onChangeBox}
                        as="textarea"
                        rows={3}
                        required
                      />
                    </Form.Group>
                    <GenericModal
                      onClick={issueBox}
                      params={{}}
                      action={"Эрх олгох"}
                      title={`Та эрх олгохдоо итгэлтэй байна уу.`}
                      body={`Та ${props?.consumer?.mobile} дугаартай хэрэглэгчид эрх олгохдоо итгэлтэй байна уу?`}
                      type={"button"}
                    />
                  </Col>
                  <Col sm="1"></Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6">
          <Card className="striped-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Хүрд эрх олгох</Card.Title>
            </Card.Header>

            <Card.Body>
              <Form>
                <Row>
                  <Col md="8">
                    <Form.Group controlId="formGridEmail">
                      <Form.Label>Нөхөн олгох эрхийн тоо</Form.Label>
                      <Form.Control
                        type="number"
                        name={"count"}
                        value={spinState.count}
                        onChange={onChangeSp}
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Тайлбар</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Тайлбар"
                        value={spinState.note}
                        name="note"
                        onChange={onChangeSp}
                        as="textarea"
                        rows={3}
                        required
                      />
                    </Form.Group>
                    <GenericModal
                      onClick={issueSpinChance}
                      params={{}}
                      action={"Эрх олгох"}
                      title={`Та эрх олгохдоо итгэлтэй байна уу.`}
                      body={`Та ${props?.consumer?.mobile} дугаартай хэрэглэгчид эрх олгохдоо итгэлтэй байна уу?`}
                      type={"button"}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Col md="8">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Box эрх цуглуулалт</Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Row>
              <Col md="12">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Огноо</th>
                      <th className="border-0">Цуглуулсан төрөл</th>
                      <th className="border-0">Хамтрагч байгуулага</th>
                      <th className="border-0">Box төрөл</th>
                      <th className="border-0">Төлөв</th>
                      <th>Дараалал</th>
                    </tr>
                  </thead>
                  <tbody>
                    {box_collect?.map((box, ind) => (
                      <tr key={ind}>
                        <td>{box?.created_at}</td>
                        <td>{box?.grant_channel}</td>
                        <td>{box?.merchant_id || ""}</td>
                        <td>{box?.type}</td>
                        <td>{box?.is_freeze ? "Боломжгүй" : "Боломжтой"}</td>
                        <td>{box?.number}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Pagination
              pagination={boxCollectPagination}
              onChange={getBoxCollect}
            />
          </Card.Body>
        </Card>
      </Col>
      <Col md="12">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Box эрх зарцуулалт</Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Row>
              <Col md="12">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      {/* <th className="border-0">Дугаар</th> */}
                      <th className="border-0">Эрх үүссэн огноо</th>
                      <th className="border-0">Цуглуулсан төрөл</th>

                      <th className="border-0">Box төрөл</th>
                      <th>Тохирлын код</th>
                      <th className="border-0">Ашигласан огноо</th>
                      <th>Төлөв</th>
                    </tr>
                  </thead>
                  <tbody>
                    {box_spend?.map((box, ind) => (
                      <tr key={ind}>
                        {/* <td>{box?.mobile}</td> */}
                        <td>{box?.created_at}</td>
                        <td>{box?.grant_channel}</td>
                        <td>{box?.box}</td>
                        <td>{box?.ticket_number}</td>
                        <td>{box?.status}</td>
                        <td>{box?.token_created_at}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  pagination={boxSpendPagination}
                  onChange={getBoxSpend}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>

      <Col md="8">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Spin эрх цуглуулалт</Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Row>
              <Col md="12">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Огноо</th>
                      <th className="border-0">Цуглуулсан төрөл</th>
                      <th className="border-0">Дугаар</th>
                      <th className="border-0">Үнэ</th>
                      <th className="border-0">...</th>
                    </tr>
                  </thead>
                  <tbody>
                    {spin_collect?.map((spin, index) => (
                      <tr key={index}>
                        <td>{spin?.created_at}</td>
                        <td>{spin?.grant_channel}</td>
                        <td>{spin?.number}</td>
                        <td>500</td>
                        <td>{spin?.barcode}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  pagination={spinCollectPagination}
                  onChange={getSpinCollect}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>

      <Col md="12">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Spin эрх зарцуулалт</Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Row>
              <Col md="12">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Огноо</th>
                      {/* <th className="border-0">Bill Number</th> */}
                      <th className="border-0">Цуглуулсан төрөл</th>
                      <th className="border-0">Receipt id</th>
                      <th className="border-0">Order id</th>
                      <th className="border-0">Эргүүлсэн огноо</th>
                      <th className="border-0">Хожсон шагнал</th>
                      <th className="border-0">Хожсон оноо</th>
                      {/* <th className="border-0">Bill Number</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {spin_spend?.map((spin, ind) => (
                      <tr key={ind}>
                        <td>{spin?.created_at}</td>
                        <td>{spin?.grant_channel}</td>
                        <td>{spin?.receipt_id}</td>
                        <td>{spin?.order_id}</td>
                        <td>{spin?.submitted_at}</td>
                        <td>{spin?.prize_name}</td>
                        <td>{spin?.prize_point}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  pagination={spinSpendPagination}
                  onChange={getSpinSpend}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>

      <Col md="8">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Даалгавар</Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Row>
              <Col md="12">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Нэр</th>
                      <th className="border-0">Төрөл</th>
                      <th className="border-0">Дэлгэрэнгүй</th>
                      <th className="border-0">Биелэсэн</th>
                      <th className="border-0">Онцгой</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mission?.map((e, ind) => (
                      <tr key={ind}>
                        <td>{e?.ui_quantity}</td>
                        <td>{e?.type}</td>
                        <td>{e?.ui_description}</td>
                        <td>{e?.has_done ? "тийм" : "үгүй"}</td>
                        <td>{e?.is_special ? "тийм" : "үгүй"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
