import request from "./request.service";

export async function getGeneralInfo(consumerId = "296499633582") {
  try {
    const res = await request(
      `/factory/general?consumer_id=${consumerId}`,
      "GET",
      {}
    );
    return res.data;
  } catch (err) {
    throw err;
  }
}
export async function getSpend(consumerId, offset, limit) {
  try {
    const res = await request(
      `/factory/spend?consumer_id=${consumerId}&limit=${limit}&offset=${offset}`,
      "GET",
      {}
    );
    return res.data;
  } catch (err) {
    throw err;
  }
}
export async function getCollect(consumerId, offset, limit) {
  try {
    const res = await request(
      `/factory/collect?consumer_id=${consumerId}&limit=${limit}&offset=${offset}`,
      "GET",
      {}
    );
    return res.data;
  } catch (err) {
    throw err;
  }
}
export async function getPurchase(consumerId, offset, limit) {
  try {
    const res = await request(
      `/factory/purchase?consumer_id=${consumerId}&limit=${limit}&offset=${offset}`,
      "GET",
      {}
    );
    return res.data;
  } catch (err) {
    throw err;
  }
}
