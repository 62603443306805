import { Button, Form, Col, Row } from "react-bootstrap";

function Filter({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    promotions,
    chosenPromotion,
    setChosenPromotion,
    handleSearch,
}) {
    return (
        <>
            <Form>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Урамшуулал</Form.Label>
                        <Form.Control
                            as="select"
                            value={chosenPromotion}
                            className="form-control"
                            onChange={(e) => {
                                setChosenPromotion(e.target.value);
                            }}
                        >
                            {/* <option key={''} value={''}>
                                Бүгд
                            </option> */}
                            {promotions.map((promotion) => (
                                <option
                                    key={promotion._id}
                                    value={promotion._id}
                                >
                                    {promotion.name_mn}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Эхлэх огноо</Form.Label>
                        <Form.Control
                            type="date"
                            format="yyyy-mm-dd"
                            value={startDate}
                            onChange={(e) => {
                                setStartDate(e.target.value);
                            }}
                        />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Дуусах огноо</Form.Label>
                        <Form.Control
                            type="date"
                            format="yyyy-mm-dd"
                            placeholder={endDate || "дуусах огноо"}
                            value={endDate}
                            onChange={(e) => {
                                setEndDate(e.target.value);
                            }}
                        />
                    </Form.Group>
                </Form.Row>
                <Button
                    variant="primary"
                    className="btn btn-sm"
                    size="md"
                    onClick={handleSearch}
                >
                    Хайх
                </Button>
            </Form>
        </>
    );
}

export default Filter;
