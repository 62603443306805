import { Card, Form } from 'react-bootstrap'
import { formatPretty } from '../../helpers/dateHelper'
import CollectiveAdd from './CollectiveAdd'

function CollectiveInfo(props) {
    if (!props.collective) {
        return <CollectiveAdd {...props} />
    }
    return (
        <Card>
            <Card.Header>
                <Card.Title as="h4">Collective мэдээлэл</Card.Title>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <label>Collective Group ID</label>
                        <Form.Control
                            value={props.collective.family_id}
                            placeholder="Овог"
                            type="text"
                            disabled
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <label>Collective Нэр</label>
                        <Form.Control
                            value={props.collective.family_name || ''}
                            placeholder="Овог"
                            type="text"
                            disabled
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <label>Үүссэн Огноо</label>
                        <Form.Control
                            value={formatPretty(props.collective.created_at) || ''}
                            placeholder="Овог"
                            type="text"
                            disabled
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <label>Дансны үлдэгдэл</label>
                        <Form.Control
                            value={props.collective.balance || 0}
                            placeholder="Овог"
                            type="text"
                            disabled
                        ></Form.Control>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    )
}
export default CollectiveInfo
