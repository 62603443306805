import { useEffect, useState } from "react";
import { Card, Table, Container, Row, Col } from "react-bootstrap";
import UPointCard from "components/CardConnection/UPointCard";
import NominCard from "components/CardConnection/NominCard";
import MonosCard from "components/CardConnection/MonosCard";
import DdishCard from "components/CardConnection/DdishCard";

export default function CardConnection(props) {
  const [upointcard, setUpointcard] = useState({});
  const [nomincard, setNomincard] = useState({});
  const [monoscard, setMonoscard] = useState({});
  const [ddishcard, setDdishcard] = useState({});
  useEffect(() => {
    props.consumer?.cards?.map((e) => {
      if (e.partner._id === "5bd95078b92b980bb3f1fb91") {
        setUpointcard(e);
      }
      if (e.partner._id === "5b0b78b53e6ae53e2a6a8592") {
        setNomincard(e);
      }
      if (e.partner._id === "5d142d215f627d62c382f155") {
        setDdishcard(e);
      }
    });
  });
  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stripied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">UPoint карт</Card.Title>
              </Card.Header>
              <Card.Body>
                <UPointCard consumer={props.consumer} card={upointcard} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="stripied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Номин карт</Card.Title>
              </Card.Header>
              <Card.Body>
                <NominCard consumer={props.consumer} card={nomincard} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="stripied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">ДДиш карт</Card.Title>
              </Card.Header>
              <Card.Body>
                <DdishCard mobile={props.consumer?.mobile} card={ddishcard} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
