import { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import MerchantServices from "../../services/merchant.service";

function UpdateEmployeeModal({
  action,
  title,
  employeeID,
  icon,
  props,
  selectedMerchant,
}) {
  const UnitelID = "5bcd43443fe112b9d6160d3d";
  const [employee, setEmployee] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(async () => {
    if (employeeID) {
      let employee = await MerchantServices.GetEmployee(
        `/merchant/employee/${employeeID}`
      );
      setEmployee(employee);
    }
  }, [employeeID]);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const onChange = (evt) => {
    setEmployee({
      ...employee,
      [evt.target.name]: evt.target.value,
    });
  };

  const onSubmit = async (evt) => {
    try {
      evt.preventDefault();
      await MerchantServices.UpdateEmployee(employeeID, employee);
      props.notify(
        "tr",
        "success",
        `${employee.username} ажилтныг амжилттай заслаа!`
      );
      setEmployee(employee);
      setShow(false);
    } catch (err) {
      props.notify("tr", "error", err.message);
    }
  };

  return (
    <div>
      <Button onClick={handleShow} size="md">
        {icon}
        {action}
      </Button>

      <Modal
        style={{ fontSize: "13px" }}
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group>
              <Form.Label>Ажилтны нэр</Form.Label>
              <Form.Control
                name="name"
                type="text"
                as="input"
                value={employee.name}
                onChange={onChange}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Нэвтрэх нэр</Form.Label>
              <Form.Control
                name="username"
                type="text"
                as="input"
                value={employee.username}
                onChange={onChange}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Нууц үг</Form.Label>
              <Form.Control
                name="password"
                type="password"
                as="input"
                // value={employee.username}
                onChange={onChange}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Албан тушаал</Form.Label>
              <Form.Control
                name="position"
                type="text"
                value={employee.position}
                onChange={onChange}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Идэвхтэй эсэх</Form.Label>
              <Form.Control
                as="select"
                name="is_active"
                type="number"
                value={employee.is_active}
                onChange={onChange}
              >
                <optgroup>
                  <option value={0}>{"0"}</option>
                  <option value={1}>{"1"}</option>
                </optgroup>
              </Form.Control>
            </Form.Group>
            {selectedMerchant === UnitelID ? (
              <>
                <Form.Group style={{ marginRight: "12px" }}>
                  <Form.Label>Dealer Number</Form.Label>
                  <Form.Control
                    value={employee.dealer_number}
                    name="dealer_number"
                    type="text"
                    required
                    onChange={onChange}
                  ></Form.Control>
                </Form.Group>
              </>
            ) : (
              ""
            )}
            <Form.Group style={{ marginRight: "12px" }}>
              <Form.Label>Role (Тайлан харах эрх) </Form.Label>
              <Form.Control
                as="select"
                value={employee.role}
                name="role"
                type="number"
                onChange={onChange}
              >
                <optgroup>
                  <option value={0}>{"0 идэвхгүй"}</option>
                  <option value={1}>{"1 идэвхтэй"}</option>
                </optgroup>
              </Form.Control>
            </Form.Group>

            <Button type="submit" style={{ width: "100%" }}>
              <i class="fa fa-plus-circle" style={{ marginRight: 6 }} />
              Засах
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UpdateEmployeeModal;
