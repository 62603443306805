import { Form, Button, Col, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import AppContext from "store/AppContext";
import { useContext } from "react";
import cardService from "services/card.service";
export default function UPointCard(props) {
  const appContext = useContext(AppContext);
  const { consumer, card } = props;
  const [cardNumber, setCardNumber] = useState("");
  const [con_ticket_number, setConTicketNum] = useState("");
  const [rem_ticket_number, setRemTicketNum] = useState("");
  const connectCard = async () => {
    console.log("replace card", cardNumber);
    try {
      if (cardNumber == "") return;
      const result = await cardService.connectCard(
        consumer?._id,
        cardNumber.trim(),
        con_ticket_number
      );
      appContext.notify("tr", "success", "Картыг амжилттай холболлоо");
      appContext.refreshConsumer();
    } catch (err) {
      appContext.notify("tr", "danger", err.message);
    }
  };
  const checkCard = async () => {
    console.log("check card");
  };
  const removeUpointCard = async () => {
    try {
      if (card.number) {
        const result = await cardService.removeCard(
          card?.partner?.name,
          card?.number,
          consumer?.mobile,
          rem_ticket_number
        );
        appContext.notify("tr", "success", result.message);
        appContext.refreshConsumer();
      }
    } catch (error) {
      console.log(error);
      appContext.notify("tr", "danger", error.message);
    }
  };
  const handleCardNumber = (e) => {
    setCardNumber(e.target.value);
  };
  useEffect(() => {
    console.log(consumer, "adfadsfasdf");
  });
  return (
    <>
      <Row>
        <Col sm="12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              removeUpointCard();
            }}
          >
            <Form.Row className="align-items-center">
              <Form.Group as={Col} controlId="formGridMobile">
                <Form.Label>Утасны дугаар</Form.Label>
                <Form.Control type="text" value={consumer?.mobile} readOnly />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridCard">
                <Form.Label>Картын дугаар</Form.Label>
                <Form.Control type="text" readOnly value={card.number} />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridCardStatus">
                <Form.Label>Баланс</Form.Label>
                <Form.Control type="text" readOnly value={card.balance} />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridCardStatus">
                <Form.Label>Төлөв</Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={
                    card.status === 1
                      ? "Идэвхитэй"
                      : card.status === 2
                      ? "Идэвхигүй"
                      : card.status === 99
                      ? "Ашиглах боломжгүй"
                      : card.status === 100
                      ? "Ашиглах боломжгүй"
                      : card.status
                  }
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridCardType">
                <Form.Label>Төрөл</Form.Label>
                <Form.Control type="text" readOnly value={card.card_type} />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridCardStatus">
                <Form.Label> </Form.Label>
                <Form.Control
                  className="form-control mr-sm-2"
                  type="text"
                  value={rem_ticket_number}
                  onChange={(e) => {
                    setRemTicketNum(e.target.value);
                    console.log(rem_ticket_number, "change");
                  }}
                  ref={appContext.inputRef}
                  placeholder="Тикет дугаар"
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridButton">
                <Col sm="1">
                  <Button
                    // onClick={removeUpointCard}
                    className="btn btn=primary"
                    type="submit"
                  >
                    Салгах
                  </Button>
                </Col>
              </Form.Group>
            </Form.Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              connectCard();
            }}
          >
            <Row>
              <Col sm="3">
                <Form.Control
                  className="form-control mr-sm-2"
                  type="text"
                  value={cardNumber}
                  onChange={handleCardNumber}
                  ref={appContext.inputRef}
                  placeholder="Шинэ картын дугаар"
                  required
                />
              </Col>

              <Col sm="3">
                <Form.Control
                  className="form-control mr-sm-2"
                  type="text"
                  value={con_ticket_number}
                  onChange={(e) => {
                    setConTicketNum(e.target.value);
                    console.log(con_ticket_number, "change");
                  }}
                  ref={appContext.inputRef}
                  placeholder="Тикет дугаар"
                  required
                />
              </Col>
              <Col sm="1">
                <Button
                  className="btn btn=primary"
                  type="submit"
                  // onClick={connectCard}
                >
                  Холбох
                </Button>
              </Col>
              {/* <Col sm="1">
                <Button
                  onClick={checkCard}
                  className="btn btn=primary"
                  type="button"
                >
                  Шалгах
                </Button>
              </Col>
              <Col sm="3">
                <b style={{ color: "green " }}>Төлөв</b>
              </Col> */}
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}
