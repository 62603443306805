import { useEffect, useState } from "react";
import { Card, Table, Col, Button, Form } from "react-bootstrap";
import Pagination from "components/Util/Pagination";
import Loading from "../Util/Loading";
import LogService from "../../services/consumer_log.service";
import {
  formatPretty,
  getCurrentDate,
  getLastWeekDate,
} from "../../helpers/dateHelper";
export default function SentNoti(props) {
  const { consumer } = props;
  const [startDate, setStartDate] = useState(getLastWeekDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState();
  const [loading, setLoading] = useState(false);
  const searchNoti = async (offset = 0, limit = 10) => {
    try {
      const result = await LogService.getNotiList(
        consumer._id,
        startDate,
        endDate,
        offset,
        limit
      );
      setList(result.data);
      setPagination(result.pagination);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {}, [props.consumer]);
  const handleSearch = async () => {
    try {
      setLoading(true);
      await searchNoti(0, 10);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Col md="12">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Хэрэглэгч рүү илгээсэн мэдэглэлүүд </Card.Title>
            <Form>
              <Form.Row className="align-items-center">
                <Form.Group as={Col} sm="2" controlId="formGridEmail">
                  <Form.Label>Эхлэх огноо</Form.Label>
                  <Form.Control
                    type="date"
                    format="yyyy-mm-dd"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group as={Col} sm="2" controlId="formGridPassword">
                  <Form.Label>Дуусах огноо</Form.Label>
                  <Form.Control
                    type="date"
                    format="yyyy-mm-dd"
                    placeholder={endDate || "дуусах огноо"}
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} sm="3" controlId="formGridPassword">
                  <Button
                    style={{ marginTop: "20px" }}
                    variant="primary"
                    className="btn btn-sm"
                    size="md"
                    onClick={handleSearch}
                  >
                    Хайх
                  </Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Table
              className="table-hover"
              style={{
                fontSize: "12px",
              }}
            >
              <thead>
                <tr style={{ wordBreak: "break-word" }}>
                  <th className="border-0">Огноо</th>
                  <th className="border-0">Гарчиг</th>
                  <th className="border-0">Дэд Гарчиг</th>
                  <th className="border-0">Текст</th>
                  <th className="border-0">Харсан</th>
                  <th className="border-0">Сервэр</th>
                </tr>
              </thead>
              <tbody style={{ wordBreak: "break-all" }}>
                {list?.map((sms, key) => (
                  <tr key={key}>
                    <td>{formatPretty(sms.created_at)}</td>
                    <td>{sms.sub_title}</td>
                    <td>{sms.title}</td>
                    <td>{sms.body}</td>
                    <td
                      style={
                        sms.is_seen ? { color: "green" } : { color: "red" }
                      }
                    >
                      {sms.is_seen ? "Тийм" : "Үгүй"}
                    </td>
                    <td>{sms.service}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Loading loading={loading} />
            <Pagination pagination={pagination} onChange={searchNoti} />
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
