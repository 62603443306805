import { Button, Modal, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import MerchantServices from "../../services/merchant.service";

function CreateBranchModal({ action, title, icon, props }) {
  const initialState = {
    name: "",
    address: "",
  };

  const [state, setState] = useState(initialState);
  const [show, setShow] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState("");

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  useEffect(async () => {
    let merchants = await MerchantServices.GetRequest("/merchant");

    setMerchants(merchants);
    setSelectedMerchant(merchants[0]._id);
  }, []);

  const onChange = (evt) => {
    setState({
      ...state,
      [evt.target.name]: evt.target.value,
    });
  };

  const onSubmit = async (evt) => {
    try {
      evt.preventDefault();
      await MerchantServices.CreateBranch(selectedMerchant, state);
      props.notify(
        "tr",
        "success",
        `${state.name} салбарыг амжилттай үүсгэлээ!`
      );
      setState(state);
      setShow(false);
    } catch (err) {
      props.notify("tr", "error", err.message);
    }
  };

  return (
    <>
      <Button onClick={handleShow} size="md">
        {icon}
        {action}
      </Button>

      <Modal
        style={{ fontSize: "13px" }}
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group>
              <Form.Label>Байгууллага сонгох</Form.Label>
              <Form.Control
                as="select"
                name={"merchant"}
                value={selectedMerchant}
                className="form-control"
                onChange={(e) => setSelectedMerchant(e.target.value)}
                required
              >
                {merchants.map((merchant, i) => (
                  <option key={i} value={merchant._id}>
                    {merchant.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Салбарын Нэр</Form.Label>
              <Form.Control
                name="name"
                type="text"
                value={state.name}
                onChange={onChange}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Салбарын хаяг</Form.Label>
              <Form.Control
                name="address"
                type="text"
                value={state.address}
                onChange={onChange}
                required
              ></Form.Control>
            </Form.Group>
            <Button type="submit" style={{ width: "100%" }}>
              <i class="fa fa-plus-circle" style={{ marginRight: 6 }} />
              Нэмэх
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default CreateBranchModal;
