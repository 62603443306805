import request from "./request.service";
import { getStartOfDate, getEndOfDate } from "../helpers/dateHelper";
export default {
    getPromos: async function (promoType) {
        try {
            let body = {};
            const response = await request(
                `/lottery/promos?promo_type=${promoType}`,
                "GET",
                body
            );
            if (response.data.code === 0) {
                return response.data.data;
            } else {
                throw Error(response.data.message);
            }
        } catch (err) {
            throw err;
        }
    },

    getConsumerTickets: async function (
        consumerId,
        startDate,
        endDate,
        promotionId
    ) {
        try {
            let startPretty = getStartOfDate(startDate);
            let endPretty = getEndOfDate(endDate);
            let body = {
                consumer_id: `${consumerId}`,
                start_date: startPretty,
                end_date: endPretty,
                promotion_id: promotionId,
            };
            const response = await request(
                `/lottery/tickets?consumer_id=${consumerId}&promotion_id=${promotionId}&offset=0&limit=50&from=${startDate}&to=${endDate}`,
                "GET",
                body
            );
            if (response.data.code === 0) {
                return response.data.data;
            } else {
                throw Error(response.data.message);
            }
        } catch (err) {
            throw err;
        }
    },
    issueTickets: async function (
        consumerId,
        promotionId,
        quantity,
        issue_note
    ) {
        try {
            let body = {
                consumer_id: `${consumerId}`,
                promotion_id: promotionId,
                quantity,
                issue_note,
            };
            const response = await request(
                "/lottery/issue_tickets",
                "POST",
                body
            );
            if (response.data.code === 0) {
                return response.data.data;
            } else {
                throw Error(response.data.message);
            }
        } catch (err) {
            throw err;
        }
    },
};
