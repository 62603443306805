import {
  Container,
  Card,
  Table,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";

function ConsumerInfo(props) {
  return (
    <Card className="strpied-tabled-with-hover">
      <Card.Header>
        <Card.Title as="h4">Хэрэглэгчийн мэдээлэл</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col sm="6">
            <Form>
              <Form.Row className="align-items-center">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Овог</Form.Label>
                  <Form.Control
                    type="text"
                    value={props.consumer.profile.first_name || ""}
                    readOnly
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Нэр</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    value={props.consumer.profile.last_name || ""}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Регистрийн дугаар</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    value={props.consumer.profile.registration_number || ""}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Утасны дугаар</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    value={props.consumer.mobile || ""}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
export default ConsumerInfo;
