import { useState, useEffect } from "react";
import { Card, Table, Col, Button, Form, Row } from "react-bootstrap";
import LogService from "../../services/consumer_log.service";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import AppContext from "store/AppContext";
import {
  formatPretty,
  getCurrentDate,
  getLastWeekDate,
} from "../../helpers/dateHelper";
import { useContext } from "react";

function SentSMS(props) {
  const [startDate, setStartDate] = useState(getLastWeekDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [smsList, setSMSList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState();
  const styleGreen = { color: "green" };
  const styleRed = { color: "red" };
  const appContext = useContext(AppContext);

  useEffect(() => {
    setSMSList([]);
    setStartDate(getLastWeekDate());
    setEndDate(getCurrentDate());
  }, [props.consumer]);

  const handleSearch = async () => {
    try {
      await searchSMS();
    } catch (err) {}
  };

  const SmsUsers = [
    "dorjkhand",
    "enkhtsetseg",
    "Baasandavaa",
    "oyunerdene",
    "Ainur",
    "chinsanaa.b",
    "chantsaldulam.ts",
    "Ganbat",
    "otgonsaikhan",
    "enkhmaa",
  ];
  const searchSMS = async (offset, limit) => {
    try {
      // if (SmsUsers.includes(props.user)) {
      setLoading(true);
      setSMSList([]);
      setPagination(null);
      let data = await LogService.getSentSMSList(
        props.consumer.mobile,
        startDate,
        endDate,
        offset,
        limit
      );
      setSMSList(data.data);
      setPagination(data.pagination);
      appContext.notify("tr", "success", "Лог хадгалагдлаа");
      // } else {
      //   appContext.notify(
      //     "tr",
      //     "danger",
      //     "Та хэрэглэгчийн мессэж харах эрхгүй байна."
      //   );
      // }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Col md="8"></Col>

      <Col md="12">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Хэрэглэгч рүү илгээсэн мессежүүд </Card.Title>
            <Form>
              <Form.Row className="align-items-center">
                <Form.Group as={Col} sm="2" controlId="formGridEmail">
                  <Form.Label>Эхлэх огноо</Form.Label>
                  <Form.Control
                    type="date"
                    format="yyyy-mm-dd"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group as={Col} sm="2" controlId="formGridPassword">
                  <Form.Label>Дуусах огноо</Form.Label>
                  <Form.Control
                    type="date"
                    format="yyyy-mm-dd"
                    placeholder={endDate || "дуусах огноо"}
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} sm="3" controlId="formGridPassword">
                  <Button
                    style={{ marginTop: "20px" }}
                    variant="primary"
                    className="btn btn-sm"
                    size="md"
                    onClick={handleSearch}
                  >
                    Хайх
                  </Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Table
              className="table-hover"
              style={{
                fontSize: "12px",
              }}
            >
              <thead>
                <tr style={{ wordBreak: "break-word" }}>
                  <th className="border-0">Огноо</th>
                  <th className="border-0">Дугаар</th>
                  <th className="border-0">Мессеж текст</th>
                  <th className="border-0">Үйлчилгээ</th>
                  <th className="border-0">Үйлдэл</th>
                  <th className="border-0">Үр дүн</th>
                </tr>
              </thead>
              <tbody style={{ wordBreak: "break-all" }}>
                {smsList.map((sms, key) => (
                  <tr key={key}>
                    <td>{formatPretty(sms.produced_at)}</td>
                    <td>{sms.mobile}</td>
                    <td>{sms.body}</td>
                    <td>{sms.action}</td>
                    <td>{sms.service}</td>
                    <td style={sms.result ? styleGreen : styleRed}>
                      {sms.result ? "Амжилттай" : "Амжилтгүй"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Loading loading={loading} />
            <Pagination pagination={pagination} onChange={searchSMS} />
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
export default SentSMS;
