import { Form, Button, Col, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import AppContext from "store/AppContext";
import { useContext } from "react";
import cardService from "services/card.service";
export default function NominCard(props) {
  const { consumer, card } = props;
  const appContext = useContext(AppContext);
  const [cardNumber, setCardNumber] = useState("");
  const [con_ticket_number, setConTicketNum] = useState("");
  const [rem_ticket_number, setRemTicketNum] = useState("");
  const handleCardNumber = (e) => {
    setCardNumber(e.target.value);
  };
  const removeNominCard = async () => {
    try {
      const result = await cardService.removeCard(
        card?.partner?.name,
        card?.number,
        consumer?.mobile,
        rem_ticket_number
      );
      appContext.notify("tr", "success", result.message);
      appContext.refreshConsumer();
    } catch (err) {
      appContext.notify("tr", "danger", err.message);
    }
  };
  const connectCard = async () => {
    console.log(cardNumber);
    try {
      const result = await cardService.connectCard(
        consumer?._id,
        cardNumber.trim(),
        con_ticket_number
      );
      appContext.notify("tr", "success", result.message);
      appContext.refreshConsumer();
    } catch (err) {
      appContext.notify("tr", "danger", err.message);
    }
  };
  useEffect(() => {
    console.log("card:", card);
  }, []);
  return (
    <>
      <Row>
        <Col sm="12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              removeNominCard();
            }}
          >
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Утасны дугаар</Form.Label>
                <Form.Control
                  className="form-control mr-sm-2"
                  type="text"
                  value={consumer?.mobile}
                  readOnly
                  ref={appContext.inputRef}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Картын дугаар</Form.Label>
                <Form.Control
                  className="form-control mr-sm-2"
                  type="text"
                  value={card?.number}
                  readOnly
                  ref={appContext.inputRef}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Баланс</Form.Label>
                <Form.Control
                  className="form-control mr-sm-2"
                  type="text"
                  value={card?.balance}
                  readOnly
                  ref={appContext.inputRef}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Төрөл</Form.Label>
                <Form.Control
                  className="form-control mr-sm-2"
                  type="text"
                  value={card?.card_type}
                  readOnly
                  ref={appContext.inputRef}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Төлөв</Form.Label>
                <Form.Control
                  className="form-control mr-sm-2"
                  type="text"
                  value={card?.status}
                  readOnly
                  ref={appContext.inputRef}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridCardStatus">
                <Form.Label> </Form.Label>
                <Form.Control
                  className="form-control mr-sm-2"
                  type="text"
                  value={rem_ticket_number}
                  onChange={(e) => {
                    setRemTicketNum(e.target.value);
                    console.log(rem_ticket_number, "change");
                  }}
                  ref={appContext.inputRef}
                  placeholder="Тикет дугаар"
                  required
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Button
                  className="btn btn=primary"
                  type="submit"
                  // onClick={removeNominCard}
                >
                  Салгах
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              connectCard();
            }}
          >
            <Row>
              <Col sm="3">
                <Form.Control
                  className="form-control mr-sm-2"
                  type="text"
                  value={cardNumber}
                  onChange={handleCardNumber}
                  ref={appContext.inputRef}
                  placeholder="Шинэ картын дугаар"
                  required
                />
              </Col>
              <Col sm="3">
                <Form.Control
                  className="form-control mr-sm-2"
                  type="text"
                  value={rem_ticket_number}
                  onChange={(e) => {
                    setRemTicketNum(e.target.value);
                    console.log(con_ticket_number, "change");
                  }}
                  ref={appContext.inputRef}
                  placeholder="Тикет дугаар"
                  required
                />
              </Col>
              <Col>
                <Button
                  className="btn btn=primary"
                  type="submit"
                  // onClick={connectCard}
                >
                  Холбох
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}
