import { Button, Form, Col, Row } from 'react-bootstrap'
import { useState } from 'react'
function FamilyEvent() {
    const [state, setState] = useState({})
    return (
        <>
            <Row>
                <Col md="8">
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} sm="2" controlId="formGridPassword">
                                <Form.Label>Event Name</Form.Label>
                                <Form.Control as="select" value={''} className="form-control">
                                    <option key={''} value={''}>
                                        Бүгд
                                    </option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} sm="3" controlId="formGridEmail">
                                <Form.Label>Эхлэх огноо</Form.Label>
                                <Form.Control type="date" format="yyyy-mm-dd" value={''} />
                            </Form.Group>

                            <Form.Group as={Col} sm="3" controlId="formGridPassword">
                                <Form.Label>Дуусах огноо</Form.Label>
                                <Form.Control type="date" format="yyyy-mm-dd" placeholder={'дуусах огноо'} value={''} />
                            </Form.Group>
                            <Form.Group as={Col} sm="2" controlId="formGridEmail">
                                <Form.Label>Хайх текст</Form.Label>
                                <Form.Control type="text" placeholder={'text'} value={''} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row></Form.Row>
                        <Button variant="primary" className="btn btn-sm" size="md">
                            Хайх
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
}

export default FamilyEvent
