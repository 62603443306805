import React, { useState, useEffect } from "react";
import LoyaltyService from "../services/loyalty.service";
import {
  Row,
  Card,
  Col,
  Table,
  Container,
  Form,
  Button,
} from "react-bootstrap";
import { formatPretty } from "helpers/dateHelper";

function EmployeeLoyalty(props) {
  const [employeeData, setEmployeeData] = useState();
  const [employeeRegistration, setEmployeeRegistration] = useState([]);
  const [employeeReward, setEmployeeReward] = useState();
  const [virtual, setVirtual] = useState([]);
  const [employeeHistory, setEmployeeHistory] = useState([]);

  useEffect(() => {
    try {
      if (!props || !props.consumer || !props.consumer.registration_number)
        return;
      let employees = LoyaltyService.GetEmployee(
        `/loyalty/consumer/${props.consumer.registration_number}`
      );
      let employeeRegistration = LoyaltyService.GetEmployee(
        `/loyalty/consumer/${props.consumer.registration_number}/log`
      );
      let employeeReward = LoyaltyService.GetEmployee(
        `/loyalty/consumer/${props.consumer.registration_number}/rule`
      );
      employees.then(function (result) {
        setEmployeeData(result);
      });
      employeeRegistration.then(function (result) {
        setEmployeeRegistration(result);
      });
      employeeReward.then(function (result) {
        setEmployeeReward(result);
      });
      let history = LoyaltyService.GetEmployee(
        `/loyalty/consumer/${props.consumer.registration_number}/history`
      );
      history.then(function (result) {
        setEmployeeHistory(result);
      });
      let reward = LoyaltyService.GetEmployee(
        `/loyalty/consumer/${props.consumer.registration_number}/reward`
      );
      reward.then(function (result) {
        setVirtual(result);
      });
    } catch (err) {
      notify("tr", "danger", `${err} алдаа гарлаа.`);
    }
  }, [props.consumer]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Row>
            {employeeData && (
              <Card style={{ marginTop: 12 }}>
                <Card.Header>
                  <Card.Title as="h4">Ажилтны профайл </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Col>
                    <Table>
                      <thead>
                        <tr>
                          <th className="border-0">Ажилтны нэр</th>
                          <th className="border-0">Харьяалах байгууллага</th>
                          <th className="border-0">Элссэн огноо</th>
                          <th className="border-0">Гарсан огноо</th>
                          <th className="border-0">Статус</th>
                        </tr>
                      </thead>
                      <tbody
                        style={{
                          wordBreak: "break-all",
                        }}
                      >
                        <tr>
                          <td>
                            {employeeData.last_name} {employeeData.first_name}
                          </td>
                          <td>{employeeData.merchant}</td>
                          <td>
                            {employeeData.work_started_at
                              ? formatPretty(employeeData.work_started_at)
                              : ""}
                          </td>
                          <td>
                            {employeeData.deleted_at
                              ? formatPretty(employeeData.deleted_at)
                              : ""}
                          </td>
                          <td>{employeeData.ui_status}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Card.Body>
              </Card>
            )}
          </Row>
          <Row>
            {virtual.length > 0 && (
              <Card style={{ marginTop: 12 }}>
                <Card.Header>
                  <Card.Title as="h4">Ажилтны шагналын түүх</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Col>
                    <Table>
                      <thead>
                        <tr>
                          <th className="border-0">Олгосон огноо</th>
                          <th className="border-0">Байгууллага</th>
                          <th className="border-0">Бэлгийн нэр</th>
                          <th className="border-0">Идэвхжүүлсэн эсэх</th>
                          <th className="border-0">Идэвхжүүлсэн огноо</th>
                        </tr>
                      </thead>
                      <tbody
                        style={{
                          wordBreak: "break-all",
                        }}
                      >
                        {virtual.map((data, idx) => (
                          <tr key={idx}>
                            <td>
                              {data.created_at
                                ? formatPretty(data?.created_at)
                                : ""}
                            </td>
                            <td>{data?.reward?.merchant?.name}</td>
                            <td>{data?.reward?.highlight}</td>
                            <td>{data?.ui_status}</td>
                            <td>
                              {data?.claimed_at
                                ? formatPretty(data?.claimed_at)
                                : ""}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Card.Body>
              </Card>
            )}
          </Row>
        </Col>
        <Col>
          {employeeRegistration.length > 0 && (
            <Card style={{ marginTop: 12 }}>
              <Card.Header>
                <Card.Title as="h4">Ажилтны Бүртгэл</Card.Title>
              </Card.Header>
              <Card.Body>
                <Col>
                  <Table>
                    <thead>
                      <tr>
                        <th className="border-0">Огноо</th>
                        <th className="border-0">Статус</th>
                        <th className="border-0">
                          Өөрчлөлт оруулсан ажилтны нэр
                        </th>
                        <th className="border-0">
                          Username-н байгууллагын нэр
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ wordBreak: "break-all" }}>
                      {employeeRegistration.map((data, idx) => (
                        <tr key={idx}>
                          <td>
                            {data.created_at
                              ? formatPretty(data.created_at)
                              : ""}
                          </td>
                          <td>{data.ui_status}</td>
                          <td>{data.admin}</td>
                          <td>{data.merchant_name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          {employeeHistory?.length > 0 && (
            <Card style={{ marginTop: 12 }}>
              <Card.Header>
                <Card.Title as="h4">Ажилтны түүх </Card.Title>
              </Card.Header>
              <Card.Body>
                <Col>
                  <Table>
                    <thead>
                      <tr>
                        <th className="border-0">Олгосон огноо</th>
                        <th className="border-0">Байгууллага</th>
                        <th className="border-0">
                          Бүргтгэлтэй үйлчилгээ/хуучин/
                        </th>
                        <th className="border-0">
                          Бүргтгэлтэй үйлчилгээ/шинэ/
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ wordBreak: "break-all" }}>
                      {employeeHistory.map((data, idx) => (
                        <tr key={idx}>
                          <td>
                            {data?.created_at
                              ? formatPretty(data?.created_at)
                              : ""}
                          </td>
                          <td>{data?.extra?.merchant_name}</td>
                          <td> {data?.extra?.previous_contract} </td>
                          <td>{data?.extra?.chosen_contract}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
      {employeeReward && (
        <Card>
          <Card.Header>
            <Card.Title as="h4"> Хамрагдах урамшуулал</Card.Title>
          </Card.Header>
          <Card.Body>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th className="border-0">Урамшуулал авч эхэлсэн огноо</th>
                    <th className="border-0">
                      Урамшуулал олгож буй байгууллага
                    </th>
                    <th className="border-0">Картын дугаар</th>
                    <th className="border-0">Өссөн хувь</th>
                    <th className="border-0">Урамшууллын төрөл</th>
                  </tr>
                </thead>
                <tbody style={{ wordBreak: "break-all" }}>
                  {employeeReward.map((employeeReward, idx) => (
                    <tr key={idx}>
                      <td>
                        {employeeReward?.created_at
                          ? formatPretty(employeeReward?.created_at)
                          : ""}
                      </td>
                      <td>{employeeReward?.title}</td>
                      <td>{employeeReward?.chosenContract}</td>
                      <td>{employeeReward?.percentage}</td>
                      <td>{employeeReward?.ui_aciton_type}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
}

export default EmployeeLoyalty;
