import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "./layouts/Admin.js";
import Login from "views/Login";
import { useState, useEffect, useRef } from "react";
import { authService } from "./services/auth.service";
import ConsumerService from "./services/consumer.service";
import CollectiveService from "./services/collective.service";
import AppContext from "./store/AppContext";
import NotificationAlert from "react-notification-alert";
import loyaltyService from "./services/loyalty.service";

function App() {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("currentUser"))
  );
  const inputRef = useRef();
  const [consumer, setConsumer] = useState();
  const [consumerLoading, setConsumerLoading] = useState(false);
  const [collective, setCollective] = useState();
  const [wallets, setWallets] = useState();
  const notificationAlertRef = useRef(null);
  const notify = (place, type, text) => {
    var type;
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{text}</div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlertRef && notificationAlertRef.current) {
      notificationAlertRef.current.notificationAlert(options);
    }
  };

  const f2Handler = (key) => {
    console.log(key);
    if (key.keyCode === 113) {
      inputRef?.current?.focus();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", f2Handler);
    return () => {
      window.removeEventListener("keydown", f2Handler);
    };
  }, []);

  useEffect(async () => {
    try {
      if (authService.currentUser) {
        authService.refreshToken();
        setInterval(function () {
          authService.refreshToken();
        }, 4 * 60 * 1000);
      }
    } catch (z) {
      console.log(z);
    }
    let subscription = authService.subscriber.subscribe((updatedUser) => {
      if (!updatedUser) {
        setConsumer(null);
        setCollective(null);
      }
      setCurrentUser(updatedUser);
    });
    return function cleanup() {
      subscription.unsubscribe();
    };
  }, []);

  const refreshCollective = async () => {
    try {
      console.log("Refreshing Collective2");
      if (!consumer || consumer.is_family != 1) {
        setCollective(null);
        return;
      }
      let collective = await CollectiveService.getCollective(consumer._id);
      setCollective(collective);
      setWallets(collective.wallets);
    } catch (err) {
      setCollective(null);
    }
  };

  const searchCollective = async (family_id) => {
    try {
      let collective = await CollectiveService.getCollectiveByFamilyId(
        family_id
      );
      let adminNumber;
      collective.members.map((member) => {
        if (member.is_admin === 1) {
          adminNumber = member.mobile;
        }
      });
      let user = await ConsumerService.searchConsumer(adminNumber);
      // console.log("User: ", user);
      user.registration_number = user.profile.registration_number;
      notify("tr", "success", `${family_id} id тай коллетив групп олдлоо`);
      setConsumer(user);
      setCollective(collective);
      setWallets(collective.wallets);
    } catch (err) {
      notify("tr", "danger", "коллектив байхгүй байна");
      setCollective(null);
    }
  };

  useEffect(refreshCollective, [consumer]);

  const searchUser = async (searchValue) => {
    try {
      setConsumerLoading(true);
      if (searchValue.length === 6) {
        searchCollective(searchValue);
      } else {
        let user = await ConsumerService.searchConsumer(searchValue);
        // console.log("User: ", user);
        user.registration_number = user.profile.registration_number;
        notify("tr", "success", `${searchValue} хэрэглэгч олдлоо`);
        setConsumer(user);
        if (!user.is_family) {
          setWallets(user.wallets);
        }
      }
    } catch (err) {
      console.log(err);
      if (
        searchValue.length === 8 &&
        searchValue[0] === "8" &&
        searchValue[0] === "9"
      ) {
        setConsumer({ mobile: searchValue });
      } else {
        setConsumer(null);
      }
      notify("tr", "danger", err.message);
      console.log(err.message);
    } finally {
      setConsumerLoading(false);
    }
  };
  const searchUserByMobile = async (searchValue) => {
    try {
      setConsumerLoading(true);
      let user = await ConsumerService.searchConsumerByMobile(searchValue);
      user.registration_number = user.profile.registration_number;
      notify("tr", "success", `${searchValue} дугаартай хэрэглэгч олдлоо`);
      setConsumer(user);
      setWallets(user.wallets);
    } catch (err) {
      console.log(err);
      if (
        searchValue.length === 8 &&
        searchValue[0] === "8" &&
        searchValue[0] === "9"
      ) {
        setConsumer({ mobile: searchValue });
      } else {
        setConsumer(null);
      }
      notify("tr", "danger", err.message);
      console.log(err.message);
    } finally {
      setConsumerLoading(false);
    }
  };
  const searchLoyaltyUser = async (searchCriteria, searchValue) => {
    try {
      setConsumer(null);
      setConsumerLoading(true);
      let searchValuePro = searchValue.replace(" ", "").toUpperCase();
      console.log(searchValuePro);
      let user = await loyaltyService.searchUser(
        searchCriteria,
        searchValuePro
      );
      notify("tr", "success", `${searchValue} дугаартай хэрэглэгч олдлоо`);
      setConsumer(user);
    } catch (err) {
      console.log(err);
      notify("tr", "danger", `${err.message}`);
    } finally {
      setConsumerLoading(false);
    }
  };
  const searchMerchantLoyaltyUser = async (searchCriteria, searchValue) => {
    try {
      setConsumer(null);
      setConsumerLoading(true);
      let searchValuePro = searchValue.replace(" ", "").toUpperCase();
      console.log(searchValuePro);
      let user = await loyaltyService.searchUser(
        searchCriteria,
        searchValuePro
      );
      notify("tr", "success", `${searchValue} дугаартай хэрэглэгч олдлоо`);
      setConsumer(user);
    } catch (err) {
      console.log(err);
      notify("tr", "danger", `${err.message}`);
    } finally {
      setConsumerLoading(false);
    }
  };

  const refreshConsumer = async () => {
    try {
      let user = await ConsumerService.getConsumer(consumer._id);
      setConsumer(user);
    } catch (err) {
      console.log(err);
      setConsumer(null);
    }
  };
  if (!currentUser) {
    window.history.replaceState(null, "Login", "/auth/login");
    return <Login />;
  }
  const contextValue = {
    inputRef: inputRef,
    currentUser: currentUser,
    consumer: consumer,
    collective: collective,

    refreshConsumer: refreshConsumer,
    searchCollective: searchCollective,
    searchUser: searchUser,
    searchUserByMobile: searchUserByMobile,
    searchLoyaltyUser: searchLoyaltyUser,
    searchMerchantLoyaltyUser: searchMerchantLoyaltyUser,
    consumerLoading: consumerLoading,
    notify: notify,
  };

  return (
    <AppContext.Provider value={contextValue}>
      <BrowserRouter>
        <Switch>
          <Route path="/admin">
            <div className="rna-container">
              <NotificationAlert ref={notificationAlertRef} />
            </div>
            <AdminLayout
              currentUser={currentUser}
              wallets={wallets}
              searchUser={searchUser}
              searchUserByMobile={searchUserByMobile}
              searchCollective={searchCollective}
              consumer={consumer}
              collective={collective}
              notify={notify}
              refreshCollective={refreshCollective}
            />
          </Route>
          <Redirect from="/" to="/admin/user" />
        </Switch>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
