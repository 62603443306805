import { useEffect, useState } from "react";
import { Card, Row, Col, Table } from "react-bootstrap";
import Pagination from "components/Util/Pagination";
import moment from "moment";
import { BarLoader } from "react-spinners";
const {
  getGeneralInfo,
  getSpend,
  getCollect,
  getPurchase,
} = require("./../services/factory.service");

export default function Factory(props) {
  const [consumerId, setConsumerId] = useState("");
  const [general, setGeneral] = useState({ all: 0, used: 0, unused: 0 });
  const [spendlist, setSpendlist] = useState([]);
  const [collectlist, setCollectlist] = useState([]);
  const [purchaselist, setPurchaselist] = useState([]);

  const [spendPagination, setSpendPagination] = useState();
  const [collectPagination, setCollectPagination] = useState();
  const [purchasePagination, setPurchasePagination] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    if (props.consumer) {
      setConsumerId(props.consumer._id);

      General();
      spend();
      collect();
      purchase();
    }
  }, [props?.consumer]);

  const General = async () => {
    try {
      setLoading(true);
      const result = await getGeneralInfo(props.consumer._id);

      setGeneral(result.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const spend = async (offset = 0, limit = 10) => {
    try {
      const res = await getSpend(props.consumer._id, offset, limit);

      setSpendlist(res.data.list);
      setSpendPagination(res.data.pagination);
    } catch (err) {
      console.log(err);
    }
  };
  const collect = async (offset = 0, limit = 10) => {
    try {
      const res = await getCollect(props.consumer._id, offset, limit);
      setCollectlist(res.data.list);
      setCollectPagination(res.data.pagination);
    } catch (err) {
      console.log(err);
    }
  };
  const purchase = async (offset = 0, limit = 10) => {
    try {
      const res = await getPurchase(props.consumer._id, offset, limit);
      setPurchaselist(res.data.list);
      setPurchasePagination(res.data.pagination);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Row>
        <Col md="6">
          <Card className="striped-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Хэрэглэгч эрх</Card.Title>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Col md="12">
                {loading ? (
                  <BarLoader />
                ) : (
                  <Table
                    className="table-hover"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th className="border-0">Нийт эрх</th>
                        <th className="border-0">Ашигласан эрх</th>
                        <th className="border-0">Ашиглаагүй эрх</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{general?.all}</td>
                        <td>{general?.used}</td>
                        <td>{general?.unused}</td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* spend */}
      <Row>
        <Col md="8">
          <Card>
            <Card.Header>
              <Card.Title as="h4">Эрх Зарцуулалт</Card.Title>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th className="border-0">Эрх Үүссэн огноо</th>
                    <th className="border-0">Тоглосон огноо</th>
                    <th className="border-0">Шагнал</th>
                    <th className="border-0">Суваг</th>
                  </tr>
                </thead>
                <tbody>
                  {spendlist?.length > 0
                    ? spendlist?.map((el, ind) => (
                        <tr key={ind}>
                          <td key={ind}>
                            {el?.created_at
                              ? moment
                                  .utc(el?.created_at.toString())
                                  .format("YYYY-MM-DD HH:mm:ss")
                              : ""}
                          </td>
                          <td>
                            {el?.played_date
                              ? moment
                                  .utc(el?.played_date)
                                  .format("YYYY-MM-DD HH:mm:ss")
                              : ""}
                          </td>
                          <td>{el?.game[0]?.prize?.name}</td>
                          <td>{el?.game[0]?.prize?.prize_type}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </Card.Body>
            <Pagination
              pagination={spendPagination}
              onChange={(offset, limit) => {
                spend(offset, limit);
              }}
            />
          </Card>
        </Col>
      </Row>
      {/* collect */}
      <Row>
        <Col md="8">
          <Card>
            <Card.Header>
              <Card.Title as="h4">Эрх Цуглуулалт</Card.Title>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th className="border-0">Билл</th>
                    <th className="border-0">Үүссэн огноо</th>
                    <th className="border-0">Тоо</th>
                    <th className="border-0">Суваг</th>
                    <th className="border-0">Дэлгэрэнгүй</th>
                  </tr>
                </thead>
                <tbody>
                  {collectlist.length > 0
                    ? collectlist?.map((el, ind) => (
                        <tr key={ind}>
                          <td>{el?.billId}</td>
                          <td>
                            {moment
                              .utc(el?.created_at.toString())
                              .format("YYYY-MM-DD HH:mm:ss")}
                          </td>
                          <td>{el?.count}</td>
                          <td>{el?.token}</td>
                          <td>{el?.description}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </Card.Body>
            <Pagination pagination={collectPagination} onChange={collect} />
          </Card>
        </Col>
      </Row>
      {/* purchase */}
      <Row>
        <Col md="8">
          <Card>
            <Card.Header>
              <Card.Title as="h4">Худалдан Авалт</Card.Title>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th className="border-0">Огноо</th>
                    <th className="border-0">Үнэ</th>
                    <th className="border-0">Суваг</th>
                    <th className="border-0">Авсан тоо</th>
                    <th className="border-0">Үр дүн</th>
                  </tr>
                </thead>
                <tbody>
                  {purchaselist.length > 0
                    ? purchaselist.map((el, ind) => (
                        <tr key={ind}>
                          <td>
                            {moment
                              .utc(el?.updated_at.toString())
                              .format("YYYY-MM-DD HH:mm:ss")}
                          </td>
                          <td>{el?.amount}</td>
                          <td>{el?.paymentChannel}</td>
                          <td>{el?.tokenCount}</td>
                          <td>{el?.status}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </Card.Body>
            <Pagination pagination={purchasePagination} onChange={purchase} />
          </Card>
        </Col>
      </Row>
    </>
  );
}
