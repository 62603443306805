import { Button, Modal, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import TransactionService from "../../services/transaction.service";
import { formatPretty } from "../../helpers/dateHelper";
import Loading from "../Util/Loading";

function TransactionDetail({ receiptId }) {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [transaction, setTransaction] = useState({});
    const handleClose = () => setShow(false);

    const handleShow = async (e) => {
        try {
            e.preventDefault();
            setShow(true);
            setLoading(true);
            let transaction = await TransactionService.getTransactionDetail(
                receiptId
            );
            setTransaction(transaction);
            setLoading(false);
        } catch (err) {
            console.log("Error in model", err);
            setShow(false);
            setLoading(false);
        }
    };

    return (
        <>
            <a href="" onClick={handleShow}>
                {receiptId}
            </a>

            <Modal
                style={{ fontSize: "13px" }}
                show={show}
                onHide={handleClose}
                animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Гүйлгээний дэлгэрэнгүй</Modal.Title>
                    <Loading loading={loading} />
                </Modal.Header>
                <Modal.Body>
                    <Table
                        className="table-hover"
                        style={{
                            fontSize: "12px",
                        }}
                    >
                        <tbody>
                            <tr>
                                <td>receipt id</td>
                                <td>{transaction._id}</td>
                            </tr>
                            <tr>
                                <td>Bill Number</td>
                                <td>{transaction.bill_number}</td>
                            </tr>
                            <tr>
                                <td>Гүйлгээ хийгдсэн огноо</td>
                                <td>{formatPretty(transaction.created_at)}</td>
                            </tr>

                            <tr>
                                <td>Буцаалттай эсэх</td>
                                <td>
                                    {transaction.is_return == 1
                                        ? "Тийм"
                                        : "Үгүй"}
                                </td>
                            </tr>
                            <tr>
                                <td>Буцаагдсан огноо</td>
                                <td>
                                    {transaction.return &&
                                        formatPretty(
                                            transaction.return.created_at
                                        )}
                                </td>
                            </tr>
                            <tr>
                                <td>Өмнөх үлдэгдэл</td>
                                <td>
                                    {transaction.return &&
                                        transaction.return.before_balance}
                                </td>
                            </tr>
                            <tr>
                                <td>Дараах үлдэгдэл</td>
                                <td>
                                    {transaction.return &&
                                        transaction.return.point_balance}
                                </td>
                            </tr>
                            <tr>
                                <td>Буцаасан оноо</td>
                                <td>
                                    {transaction.return &&
                                        transaction.return.refund_bonus_amount}
                                </td>
                            </tr>
                            <tr>
                                <td>Нөхөн олгосон оноо</td>
                                <td>
                                    {transaction.return &&
                                        transaction.return.refund_spend_amount}
                                </td>
                            </tr>
                            <tr>
                                <td>Бэлэн мөнгөөр буцаан олгосон</td>
                                <td>
                                    {transaction.return &&
                                        transaction.return.refund_cash_amount}
                                </td>
                            </tr>
                            <tr>
                                <td>Бэлэн мөнгөөр төлсөн</td>
                                <td>
                                    {transaction.return &&
                                        transaction.return.cash_amount}
                                </td>
                            </tr>
                            <tr>
                                <td>Цуцлагдсан эсэх</td>
                                <td>
                                    {transaction.return &&
                                    transaction.return.is_reverse == 1
                                        ? "Тийм"
                                        : "Үгүй"}
                                </td>
                            </tr>

                            {transaction.items ? (
                                <tr>
                                    <td>Бүтээгдэхүүнүүд</td>
                                    <tr>
                                        <td>Нэр:</td>
                                        <td>Тоо:</td>
                                        <td>Point:</td>
                                        <td>Үнэ:</td>
                                        <td>barcode:</td>
                                    </tr>
                                    {transaction?.items.map((item, id) => {
                                        return (
                                            <tr key={id}>
                                                <td>{item.name}</td>
                                                <td>{item.qty}</td>
                                                <td>{item.point}</td>
                                                <td>{item.price}</td>
                                                <td>{item.barcode}</td>
                                            </tr>
                                        );
                                    })}
                                </tr>
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </Table>{" "}
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" onClick={handleClose}>
                        Хаах
                    </Button>
                    {/* <Button size="sm" className="pull-right" onClick={handleConfirm}>
                        Группээс хасах
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default TransactionDetail;
