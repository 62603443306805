import { Container, Row } from 'react-bootstrap'
import EmployeeLog from '../components/Log/EmployeeLog'
import EmployeeTransactions from '../components/Transaction/EmployeeTransactions'

function Log(props) {
    return (
        <>
            <Container fluid>
                <Row>
                    <EmployeeLog consumer={props.consumer} />
                </Row>
                <Row>
                    <EmployeeTransactions consumer={props.consumer} />
                </Row>
            </Container>
        </>
    )
}

export default Log
