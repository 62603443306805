import { Table, Button, Form, Col, Row } from 'react-bootstrap'

function TransactionTotal({ total }) {
    if (!total) {
        return null
    }
    return (
        <>
            <Table
                className="table-hover"
                style={{
                    fontSize: '12px',
                }}
            >
                <thead>
                    <tr>
                        <th className="border-0">Total Point</th>
                        <th className="border-0">Total Amount</th>
                        <th className="border-0">Bonus Amount</th>
                        <th className="border-0">Spend Amount</th>
                        <th className="border-0">Transaction count</th>
                    </tr>
                </thead>

                <tbody style={{ wordBreak: 'break-all' }}>
                    <tr key={11}>
                        <td>{total.total_point}</td>
                        <td>{total.total_amount}</td>
                        <td>{total.bonus_amount}</td>
                        <td>{total.total_spend_amount}</td>
                        <td>{total.transaction_count}</td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}

export default TransactionTotal
