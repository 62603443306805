import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CreateEmployee from "../components/Settings/CreateEmployee";
import EmployeeList from "../components/Settings/EmployeeList";
import RestService from "../services/rest.service";
function UserSettings(props) {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const refreshEmployees = async () => {
    try {
      setLoading(true);
      let employees = await RestService.getUsers();
      setEmployees(employees);
    } catch (err) {
      console.log(err);
      setEmployees([]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(refreshEmployees, []);
  return (
    <Container fluid>
      <Row>
        <Col>
          <EmployeeList
            employees={employees}
            refreshEmployees={refreshEmployees}
            notify={props.notify}
            loading={loading}
            currentUser={props.currentUser}
          />
        </Col>
      </Row>
      <Row>
        <Col md="5">
          <CreateEmployee
            currentUser={props.currentUser}
            refreshEmployees={refreshEmployees}
            notify={props.notify}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default UserSettings;
