import { useEffect, useState } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import PromoService from "../../services/promo.service";
import Loading from "components/Util/Loading";
import Pagination from "components/Util/Pagination";
import Filter from "./Filter";
import {
  formatPretty,
  getLastWeekDate,
  getCurrentDate,
  formatBeauty,
} from "../../helpers/dateHelper";

function BadgeHistory(props) {
  const [badges, setBadges] = useState([]);
  const [loading, setLoading] = useState(false);
  const styleGreen = { color: "green" };
  const styleRed = { color: "red" };

  const [startDate, setStartDate] = useState(getLastWeekDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [badgeChoices, setBadgeChoices] = useState([]);
  const [chosenBadge, setChosenBadge] = useState("");

  const [pagination, setPagination] = useState();

  // const getBadges = async () => {
  //     try {
  //         setLoading(true)
  //         setBadges([])
  //         let data = await PromoService.consumerBadgesHistory(props.consumer._id)
  //         setBadges(data.badges)
  //         console.log(data.badges)
  //     } catch (err) {
  //         console.log(err)
  //     } finally {
  //         setLoading(false)
  //     }
  // }

  const handleSearch = async () => {
    try {
      await getBadges();
    } catch (err) {}
  };

  const getBadges = async (offset, limit) => {
    try {
      console.log(offset, limit, chosenBadge);
      setLoading(true);
      setBadges([]);
      setPagination(null);
      let data = await PromoService.consumerBadgesHistory(
        props.consumer._id,
        chosenBadge,
        startDate,
        endDate,
        offset,
        limit
      );
      setBadges(data.badges);
      //   console.log(data.badges);
      //   console.log(data.pagination);
      setPagination(data.pagination);
    } catch (err) {
      console.log(err);
      props.notify("tr", "danger", err.message);
    } finally {
      setLoading(false);
    }
  };

  const getBadgeChoices = async (offset, limit) => {
    try {
      setLoading(true);
      setBadgeChoices([]);
      let data = await PromoService.getBadges();
      setBadgeChoices(data.badges);
    } catch (err) {
      console.log(err);
      props.notify("tr", "danger", err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    try {
      await getBadgeChoices();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setBadges([]);
    setPagination(null);
  }, [props.consumer]);

  return (
    <>
      <Card className="strpied-tabled-with-hover">
        <Card.Header>
          <Card.Title as="h4">Цолны түүх </Card.Title>
          <Row>
            <Col md="8">
              <Filter
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                badgeChoices={badgeChoices}
                chosenBadge={chosenBadge}
                setChosenBadge={setChosenBadge}
                handleSearch={handleSearch}
              />
            </Col>
          </Row>
          {/* <Row>
                                    <Col md="8">
                                        <Filter
                                            startDate={startDate}
                                            setStartDate={setStartDate}
                                            endDate={endDate}
                                            setEndDate={setEndDate}
                                            merchants={merchantList}
                                            chosenMerchant={chosenMerchant}
                                            setChosenMerchant={setChosenMerchant}
                                            badgeType={badgeType}
                                            setbadgeType={setbadgeType}
                                            userType={userType}
                                            setUserType={setUserType}
                                            handleSearch={handleSearch}
                                        />
                                    </Col>
                                </Row> */}
        </Card.Header>
        <Card.Body className="table-full-width table-responsive px-0">
          <Table
            className="table-hover"
            style={{
              fontSize: "12px",
            }}
          >
            <thead>
              <tr>
                <th>Зураг</th>
                <th className="border-0">Цол</th>
                <th className="border-0">Байгууллага</th>
                <th className="border-0">Үүссэн огноо</th>
                <th className="border-0">Эхлэх огноо</th>
                <th className="border-0">Дуусах огноо</th>
                <th className="border-0">Статус</th>
              </tr>
            </thead>

            <tbody style={{ wordBreak: "break-all" }}>
              {badges.map((badge) => (
                <tr key={badge._id}>
                  <td>
                    <img
                      src={badge.badge_id.logo}
                      height="40"
                      width="40"
                      class="img-fluid img-thumbnail"
                      alt="Sheep"
                    />
                  </td>
                  <td>{badge.badge_id.name}</td>
                  <td>{badge.badge_id.merchant.name}</td>
                  <td>{formatPretty(badge.created_at)}</td>
                  <td>{formatPretty(badge.start_date)}</td>
                  <td>{formatPretty(badge.expire_date)}</td>
                  <td style={badge.status ? styleGreen : styleRed}>
                    {badge.status ? "active" : "inactive"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Loading loading={loading} />
          <Pagination pagination={pagination} onChange={getBadges} />
        </Card.Body>
      </Card>
    </>
  );
}

export default BadgeHistory;
